// assets
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import RouteOutlinedIcon from '@mui/icons-material/RouteOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const IntegrationRoutes: INavGroup = {
  id: 'group-integration',
  title: 'Integração RD',
  icon: <DatasetLinkedIcon />,
  type: 'group',
  module: 'INTEGRATION',
  level: 1,
  children: [
    {
      id: 'link_representative',
      title: 'Vincular Representantes',
      type: 'item',
      url: ROUTES.authenticated.integration.link_representative,
      icon: <SupportAgentIcon />,
      breadcrumbs: true,
      submodule: 'LINK_REPRESENTATIVE'
    },
    {
      id: 'link_sale_division',
      title: 'Vincular Divisões de Venda',
      type: 'item',
      url: ROUTES.authenticated.integration.link_sale_division,
      icon: <PointOfSaleOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'LINK_SALE_DIVISION'
    },
    {
      id: 'link_customer',
      title: 'Vincular Clientes',
      type: 'item',
      url: ROUTES.authenticated.integration.link_customer,
      icon: <AccountCircleOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'LINK_CUSTOMER'
    },
    {
      id: 'link_product',
      title: 'Vincular Produtos',
      type: 'item',
      url: ROUTES.authenticated.integration.link_product,
      icon: <Inventory2OutlinedIcon />,
      breadcrumbs: true,
      submodule: 'LINK_PRODUCT'
    },
    {
      id: 'link_sales_channel',
      title: 'Vincular Canais de Venda',
      type: 'item',
      url: ROUTES.authenticated.integration.link_sales_channel,
      icon: <RouteOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'LINK_SALES_CHANNEL'
    },
    {
      id: 'integration_log',
      title: 'Logs da Integração',
      type: 'item',
      url: ROUTES.authenticated.integration.integration_log,
      icon: <SummarizeOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'INTEGRATION_LOG'
    }
  ]
};
