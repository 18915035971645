import { HelmetMeta } from '../../../layout/HelmetMeta';
import { CommercialDashboardPage } from '../../../sections/@commercial-dashboard/CommercialDashboardPage';
import { CommercialDashboardPageContextProvider } from '../../../sections/@commercial-dashboard/context/CommercialDashboardContext';

export const CommercialDashboardPageWrapper = () => (
  <CommercialDashboardPageContextProvider>
    <HelmetMeta pageName="Painel Comercial" />
    <CommercialDashboardPage />
  </CommercialDashboardPageContextProvider>
);
