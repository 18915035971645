import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { formatDateToApi, formatSimpleDate } from '../../../utils/utils';
import { ExceedTargetFilterFields, useExceedTargetsPageContext } from '../context/ExceedTargetContext';

interface Props {
  onClose: () => void;
}

const getDefaultValues = (filter?: ExceedTargetFilterFields): ExceedTargetFilterFields => {
  return {
    target_date: filter?.target_date ? formatSimpleDate(filter.target_date) : ''
  };
};

export const ExceedTargetFilterDialog = ({ onClose }: Props) => {
  const { filterFields, setFilterFields } = useExceedTargetsPageContext();

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ExceedTargetFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit: SubmitHandler<ExceedTargetFilterFields> = async (data: ExceedTargetFilterFields) => {
    data = {
      ...data,
      target_date: formatDateToApi(data.target_date)
    };
    setFilterFields({ ...data } as ExceedTargetFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as ExceedTargetFilterFields);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel htmlFor="target_date">Período</InputLabel>
              <ControlledTextInput
                name="target_date"
                placeholder="Período"
                control={control}
                fullWidth
                mask="99/99/9999"
                errorMessage={errors.target_date?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
