import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { ExceedTarget } from '../models/ExceedTarget';
import { ExceedTargetFilterFields } from '../sections/@exceed-target/context/ExceedTargetContext';

const ExceedTargetUrl = '/commercial/exceed-target/';

export const ExceedTargetService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ExceedTargetUrl);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },
  get: async (filterFields: ExceedTargetFilterFields, page: number = 0, page_size: number = 0) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ExceedTargetUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: ExceedTarget) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(ExceedTargetUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: ExceedTarget) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${ExceedTargetUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${ExceedTargetUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${ExceedTargetUrl}batch-delete/`, {
        ids: ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
