import { CircularProgress, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useState } from 'react';
import { StyledTableRow } from '../../../../components/basics/StyledTableRow';
import { PermissionsObj } from '../../../../hooks/useCrudPermissions';
import { IndicatorDetail } from '../../../../models/IndicatorDetail';
import { IndicatorGrouped } from '../../../../models/IndicatorGrouped';
import { formatCurrency } from '../../../../utils/utils';
import { useCommercialDashboardsPageContext } from '../../context/CommercialDashboardContext';

interface Props {
  permissions: PermissionsObj;
  selectedRow: IndicatorGrouped;
  onRowClick: (sales_channel: number, representative: number, type: string) => void;
}

export const CommercialDashboardDetailsTable = ({ onRowClick, permissions, selectedRow }: Props) => {
  const { indicatorDetail, indicatorDetailTotals, fetchIndicatorDetails, showComparison, analysisType } =
    useCommercialDashboardsPageContext();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      await fetchIndicatorDetails(selectedRow?.sales_channel?.id);
    };
    setLoading(true);
    loadData();
    setLoading(false);
  }, [selectedRow]);

  return (
    <Box sx={{ width: '100%' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#c1c1c1' }}>
                  <TableCell>
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      {selectedRow?.sales_channel?.detail_description.toLocaleUpperCase() || 'REPRESENTANTE'}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      META SUPERAÇÃO
                    </Typography>
                  </TableCell>
                  {showComparison && (
                    <>
                      <TableCell align="center">
                        <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                          META SUPERAÇÃO ANTERIOR
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                          % META SUPERAÇÃO ANTERIOR
                        </Typography>
                      </TableCell>
                    </>
                  )}
                  <TableCell align="center">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      META
                    </Typography>
                  </TableCell>
                  {showComparison && (
                    <>
                      <TableCell align="center">
                        <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                          META ANTERIOR
                        </Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                          % META ANTERIOR
                        </Typography>
                      </TableCell>
                    </>
                  )}
                  <TableCell align="center">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      % ATINGIDO META
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      FATURADOS
                    </Typography>
                  </TableCell>
                  {showComparison && (
                    <TableCell align="center">
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        FATURADOS ANTERIOR
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell align="center">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      CARTEIRA
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      PREVISAO DE VENDA
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      TOTAL
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      DIF SUPERAÇÃO
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      % DIF SUPERAÇÃO
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      DIF META
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      % DIF META
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {indicatorDetail && indicatorDetail?.count > 0 ? (
                  <>
                    {indicatorDetail?.results?.results?.map((row: IndicatorDetail, index: number) => {
                      return (
                        <StyledTableRow role="checkbox" tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                          <TableCell align="left" sx={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                            {row?.representative_description.toLocaleUpperCase()}
                          </TableCell>
                          <TableCell align="right">{formatCurrency(row.exceed_target_value)}</TableCell>
                          {showComparison && (
                            <>
                              <TableCell align="right">{formatCurrency(row.exceed_target_value_previous)}</TableCell>
                              <TableCell align="right">{row.percent_exceed_target_previous} %</TableCell>
                            </>
                          )}
                          <TableCell align="right">{formatCurrency(row.target_value)}</TableCell>
                          {showComparison && (
                            <>
                              <TableCell align="right">{formatCurrency(row.target_value_previous)}</TableCell>
                              <TableCell align="right">{row.percent_target_previous} %</TableCell>
                            </>
                          )}
                          <TableCell align="right">{row.target_percent} %</TableCell>
                          <TableCell
                            align="right"
                            onClick={analysisType ? () => onRowClick(row?.sales_channel, row?.representative, 'NFS') : undefined}
                          >
                            {formatCurrency(row.realized_revenue)}
                          </TableCell>
                          {showComparison && <TableCell align="right">{formatCurrency(row.realized_revenue_previous)}</TableCell>}
                          <TableCell
                            align="right"
                            onClick={analysisType ? () => onRowClick(row?.sales_channel, row?.representative, 'PDV') : undefined}
                          >
                            {formatCurrency(row.portfolio)}
                          </TableCell>
                          <TableCell align="right">{formatCurrency(row.forecasted_revenue)}</TableCell>
                          <TableCell align="right">{formatCurrency(row.total)}</TableCell>
                          <TableCell align="right">{formatCurrency(row.diff_exceed_target)}</TableCell>
                          <TableCell align="right">{row.percent_diff_exceed_target} %</TableCell>
                          <TableCell align="right">{formatCurrency(row.diff_target)}</TableCell>
                          <TableCell align="right">{row.percent_diff_target} %</TableCell>
                        </StyledTableRow>
                      );
                    })}
                  </>
                ) : (
                  <StyledTableRow>
                    <TableCell align="center" colSpan={17}>
                      Nenhum registro encontrado
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
              {indicatorDetailTotals && (
                <TableFooter>
                  <TableRow>
                    <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                      TOTAIS
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                      {indicatorDetailTotals.exceed_target_value_total
                        ? formatCurrency(indicatorDetailTotals.exceed_target_value_total)
                        : '-'}
                    </TableCell>
                    {showComparison && (
                      <>
                        <TableCell
                          align="right"
                          sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}
                        >
                          {indicatorDetailTotals.exceed_target_value_previous_total
                            ? formatCurrency(indicatorDetailTotals.exceed_target_value_previous_total)
                            : '-'}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}
                        >
                          -
                        </TableCell>
                      </>
                    )}
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                      {indicatorDetailTotals.target_value_total ? formatCurrency(indicatorDetailTotals.target_value_total) : '-'}
                    </TableCell>
                    {showComparison && (
                      <>
                        <TableCell
                          align="right"
                          sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}
                        >
                          {indicatorDetailTotals.target_value_previous_total
                            ? formatCurrency(indicatorDetailTotals.target_value_previous_total)
                            : '-'}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}
                        >
                          -
                        </TableCell>
                      </>
                    )}
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                      -
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                      {indicatorDetailTotals.realized_revenue_total ? formatCurrency(indicatorDetailTotals.realized_revenue_total) : '-'}
                    </TableCell>
                    {showComparison && (
                      <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                        {indicatorDetailTotals.realized_revenue_previous_total
                          ? formatCurrency(indicatorDetailTotals.realized_revenue_previous_total)
                          : '-'}
                      </TableCell>
                    )}
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                      {indicatorDetailTotals.portfolio_total ? formatCurrency(indicatorDetailTotals.portfolio_total) : '-'}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                      {indicatorDetailTotals.forecasted_revenue_total
                        ? formatCurrency(indicatorDetailTotals.forecasted_revenue_total)
                        : '-'}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                      {indicatorDetailTotals.total_total ? formatCurrency(indicatorDetailTotals.total_total) : '-'}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                      {indicatorDetailTotals.diff_exceed_target_total
                        ? formatCurrency(indicatorDetailTotals.diff_exceed_target_total)
                        : '-'}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                      -
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                      {indicatorDetailTotals.diff_target_total ? formatCurrency(indicatorDetailTotals.diff_target_total) : '-'}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000', whiteSpace: 'nowrap' }}>
                      -
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
};
