import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, InputLabel } from '@mui/material'; // Import Tab components
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { FormMessage } from '../../../models/FormMessage';
import { Representative } from '../../../models/Representative';
import { RepresentativeUser } from '../../../models/RepresentativeUser';
import { User } from '../../../models/User';
import { RepresentativesService } from '../../../services/Representative.service';
import { RepresentativeUserService } from '../../../services/RepresentativeUser.service';
import { UsersService } from '../../../services/Users.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { RepresentativeUserSchema } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useRepresentativeUsersPageContext } from '../context/RepresentativeUserPageContext';

interface Props {
  editItem?: RepresentativeUser;
  onClose: () => void;
}

export const RepresentativeUserFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [users, setUsers] = useState<SelectOption[]>([]);
  const [representatives, setRepresentatives] = useState<SelectOption[]>([]);

  const [selectedRepresentative, setSelectedRepresentative] = useState<SelectOption>({} as SelectOption);
  const [selectedUser, setSelectedUser] = useState<SelectOption>({} as SelectOption);

  const { fetchRepresentativeUser } = useRepresentativeUsersPageContext();

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors }
  } = useForm<RepresentativeUser>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(RepresentativeUserSchema),
    defaultValues: editItem
      ? {
          ...editItem,
          user: {
            id: editItem.user?.id,
            name: `${editItem.user?.first_name} ${editItem.user?.last_name}`
          } as SelectOption,
          focco_representative: {
            id: editItem.focco_representative.id,
            name: `${editItem.focco_representative.code} - ${editItem.focco_representative.description}`,
            cnpj: editItem.focco_representative.cnpj,
            cpf: editItem.focco_representative.cpf
          } as SelectOption
        }
      : {}
  });

  const formValidations = () => {
    if (!selectedRepresentative?.id) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Selecione um Reresentante',
          severity: 'error'
        })
      );
      return false;
    }

    return true;
  };

  const onSubmitForm: SubmitHandler<RepresentativeUser> = async (data: RepresentativeUser) => {
    if (!formValidations()) return;

    data = {
      ...data,
      user: data?.user?.id || null,
      focco_representative: selectedRepresentative.id
    };
    dispatch(showSpinner());
    try {
      const response = editItem?.id ? await RepresentativeUserService.update(data) : await RepresentativeUserService.create(data);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Vínculo atualizado com sucesso!' : 'Vínculo criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchRepresentativeUser();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await UsersService.get({ is_active: true });
      if (response?.data?.results) {
        const dataUsers = response?.data?.results;
        setUsers(
          dataUsers.map((user: User) => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Usuários',
          severity: 'error'
        })
      );
    }
  };

  const fetchRepresentative = async (watchRepresentativeSearch?: string) => {
    try {
      const response = await RepresentativesService.get({ search: watchRepresentativeSearch });
      if (response?.data?.results) {
        const dataRepresentatives = response?.data?.results;
        setRepresentatives(
          dataRepresentatives.map((representative: Representative) => ({
            id: representative.id,
            name: `${representative.code} - ${representative.description}`,
            cnpj: representative.cnpj,
            cpf: representative.cpf
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Representantes Focco',
          severity: 'error'
        })
      );
    }
  };

  const handleSelectRepresentative = (value: SelectOption) => {
    setSelectedRepresentative(value);
  };

  useEffect(() => {
    if (editItem?.focco_representative) {
      const representative = editItem.focco_representative as Representative;
      setSelectedRepresentative({ id: representative.id, name: `${representative.code} - ${representative.description}` });
      setRepresentatives([{ id: representative.id, name: `${representative.code} - ${representative.description}` }]);
    }
    fetchUsers();
  }, [editItem]);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Vínculo de Representante x Usuário
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {formMessage?.message && (
                <Grid item xs={12}>
                  <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                    {formMessage.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_representative">Representantes</InputLabel>
              <AutocompleteSearch
                value={selectedRepresentative}
                defaultValue={
                  editItem?.focco_representative
                    ? {
                        id: editItem.focco_representative.id,
                        name: `${editItem.focco_representative.code} - ${editItem.focco_representative.description}`
                      }
                    : undefined
                }
                onSearch={fetchRepresentative}
                selectOptions={representatives}
                onSelect={(event, value: SelectOption) => handleSelectRepresentative(value)}
                errorMessage={errors.focco_representative?.message}
                getOptionLabel={(option) =>
                  `${option.name} ${option.cnpj ? ` - CNPJ: ${option.cnpj}` : ''} ${option.cpf ? ` - CPF: ${option.cpf}` : ''}`
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="user">Usuário</InputLabel>
              <ControlledComboBox name="user" control={control} errorMessage={errors.user?.message?.toString()} selectOptions={users} />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
