import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { RepresentativeUser } from '../../../models/RepresentativeUser';
import { formatCpfCnpj } from '../../../utils/utils';

interface Props {
  item: RepresentativeUser;
  onClose: () => void;
}

export const RepresentativeUserDetailsDialog = ({ item, onClose }: Props) => {
  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes do Vínculo de Representante X Usuário
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Código Representante:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.focco_representative.code}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Nome Representante:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.focco_representative.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Nome Fantasia:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.focco_representative.trade_name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Usuário:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.user?.first_name} {item.user?.last_name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              CNPJ/CPF:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.focco_representative.cnpj
                ? formatCpfCnpj(item.focco_representative.cnpj)
                : item.focco_representative.cpf
                ? formatCpfCnpj(item.focco_representative.cpf)
                : '-'}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
