import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SalesChannel } from '../../../models/SalesChannel';

interface Props {
  item: SalesChannel;
  onClose: () => void;
}

const criteriasDict = {
  sale_division: 'Divisão de Venda',
  market_segment: 'Segmento',
  customer_type: 'Tipo de Cliente',
  representative_type: 'Tipo de Representante'
};
const typesDict = {
  F: 'Financeiro',
  C: 'Comercial'
};

export const SalesChannelDetailsDialog = ({ item, onClose }: Props) => {
  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes do Canal de Venda
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Descrição:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item?.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Situação:
            </Typography>
            <>{item.status ? <Alert severity="success">Ativo</Alert> : <Alert severity="error">Inativo</Alert>}</>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Tipo:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {typesDict[item?.type!]}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Descrição do Detalhamento:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item?.detail_description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Critérios:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ul>
                {item.criterias?.map((d) => (
                  <>
                    <li>{criteriasDict[d.criteria_type]}</li>
                    <ul>
                      {d.criteria_values?.map((d) => (
                        <li>
                          {d.erp_id?.code} - {d.erp_id?.name}
                        </li>
                      ))}
                    </ul>
                  </>
                ))}
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
