import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { InventoryGroup } from '../../../models/InventoryGroup';
import { InventoryGroupService } from '../../../services/InventoryGroup.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface InventoryGroupFilterFields extends InventoryGroup {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface InventoryGroupResult extends ApiResults {
  results: InventoryGroup[];
}

interface InventoryGroupContextProps {
  inventoryGroups: InventoryGroupResult | undefined;
  fetchInventoryGroups: () => void;
  deleteInventoryGroups: (id: number) => Promise<void>;
  deleteManyInventoryGroups: (items: string[]) => Promise<void>;
  filterFields: InventoryGroupFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<InventoryGroupFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const InventoryGroupsPageContext = createContext<InventoryGroupContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const InventoryGroupPageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [inventoryGroups, setInventoryGroups] = useState<InventoryGroupResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<InventoryGroupFilterFields>({} as InventoryGroupFilterFields);

  const fetchInventoryGroups = async () => {
    dispatch(showSpinner());

    try {
      const response = await InventoryGroupService.get(filterFields, page, rowsPerPage);
      if (checkResponseStatus(response)) {
        setInventoryGroups(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteInventoryGroups = async (id: number) => {
    dispatch(showSpinner());
    try {
      await InventoryGroupService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registro excluído com sucesso!',
          severity: 'success'
        })
      );
      fetchInventoryGroups();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyInventoryGroups = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await InventoryGroupService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registros excluídos com sucesso!',
          severity: 'success'
        })
      );
      fetchInventoryGroups();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail ?? 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchInventoryGroups();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: InventoryGroupContextProps = {
    inventoryGroups,
    fetchInventoryGroups,
    deleteInventoryGroups,
    deleteManyInventoryGroups,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <InventoryGroupsPageContext.Provider value={contextValue}>{children}</InventoryGroupsPageContext.Provider>;
};

export const useInventoryGroupsPageContext = () => {
  const context = useContext(InventoryGroupsPageContext);
  if (!context) {
    throw new Error('useInventoryGroupsPageContext must be used within a InventoryGroupsPageContextProvider');
  }
  return context;
};
