import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider, Grid, IconButton, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { FormMessage } from '../../../models/FormMessage';
import { InventoryGroup } from '../../../models/InventoryGroup';
import { Representative } from '../../../models/Representative';
import { User } from '../../../models/User';
import { InventoryGroupService } from '../../../services/InventoryGroup.service';
import { UsersService } from '../../../services/Users.service';
import { WarehouseService } from '../../../services/Warehouse.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { InventoryGroupScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useInventoryGroupsPageContext } from '../context/InventoryGroupContext';

interface Props {
  editItem?: InventoryGroup;
  onClose: () => void;
}

export const InventoryGroupFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);

  const initialLinkedWarehouses = useMemo(
    () =>
      (editItem?.warehouses || []).map((rep) => ({
        ...rep,
        name: `${rep.code} - ${rep.description}`
      })),
    [editItem]
  );

  const initialSelectedWarehouses = useMemo(
    () =>
      initialLinkedWarehouses.reduce((acc, rep, index) => {
        acc[index] = { id: rep.id, name: `${rep.code} - ${rep.description}` };
        return acc;
      }, {} as { [key: number]: SelectOption }),
    [initialLinkedWarehouses]
  );

  const [linkedWarehouses, setLinkedWarehouses] = useState(initialLinkedWarehouses);
  const [warehouses, setWarehouses] = useState<SelectOption[]>([]);
  const [users, setUsers] = useState<SelectOption[]>([]);
  const [selectedWarehouses, setSelectedWarehouses] = useState(initialSelectedWarehouses);
  const { fetchInventoryGroups } = useInventoryGroupsPageContext();

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    unregister,
    reset,
    formState: { errors }
  } = useForm<InventoryGroup>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(InventoryGroupScheme),
    defaultValues: editItem
      ? {
          ...editItem,
          supervisor: editItem?.supervisor
            ? ({ id: editItem.supervisor?.id, name: `${editItem.supervisor.first_name} ${editItem.supervisor.last_name}` } as SelectOption)
            : null
        }
      : {
          include_overall: true
        }
  });

  const watchIncludeOverall = useWatch({
    control,
    name: 'include_overall',
    defaultValue: getValues('include_overall')
  });

  const onSubmitForm: SubmitHandler<InventoryGroup> = async (data: InventoryGroup) => {
    const warehouse_ids = Array.from(new Set(linkedWarehouses.map((warehouse) => warehouse.id)));
    const payload = {
      id: editItem?.id || null,
      description: data.description,
      include_overall: data.include_overall,
      supervisor: data?.supervisor?.id || null,
      warehouse_ids: warehouse_ids
    };
    dispatch(showSpinner());

    try {
      const response = editItem?.id ? await InventoryGroupService.update(payload) : await InventoryGroupService.create(payload);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Grupo de Estoque atualizado com sucesso!' : 'Grupo de Estoque criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchInventoryGroups();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchWarehouse = async (watchWarehouseSearch?: string) => {
    try {
      const response = await WarehouseService.get({ search: watchWarehouseSearch });
      if (response?.data?.results) {
        const dataRepresentatives = response?.data?.results;
        setWarehouses(
          dataRepresentatives.map((warehouse: Representative) => ({
            id: warehouse.id,
            name: `${warehouse.code} - ${warehouse.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Almoxarifados ERP',
          severity: 'error'
        })
      );
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await UsersService.get({ is_active: true });
      if (response?.data?.results) {
        const dataUsers = response?.data?.results;
        setUsers(
          dataUsers.map((user: User) => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Usuários',
          severity: 'error'
        })
      );
    }
  };

  const handleSelectWarehouse = (value: SelectOption, index: number) => {
    setSelectedWarehouses({
      ...selectedWarehouses,
      [index]: value
    });

    const updateWarehouses = [...linkedWarehouses];
    updateWarehouses[index] = {
      ...updateWarehouses[index],
      id: value.id,
      description: value.name,
      code: value.code
    };
    setLinkedWarehouses(updateWarehouses);
  };

  const handleAddLinkedRepresentative = () => {
    setLinkedWarehouses([...linkedWarehouses, { id: null, description: '', code: '' }]);
    setSelectedWarehouses({
      ...selectedWarehouses,
      [linkedWarehouses.length]: {}
    });
  };

  const handleDeleteLinkedRepresentative = (index: number) => {
    const updateWarehouses = linkedWarehouses.filter((_, i) => i !== index);

    const updatedSelectedRepresentatives: { [key: number]: SelectOption } = {};
    updateWarehouses.forEach((rep, i) => {
      updatedSelectedRepresentatives[i] = selectedWarehouses[i >= index ? i + 1 : i];
    });

    setLinkedWarehouses(updateWarehouses);
    setSelectedWarehouses(updatedSelectedRepresentatives);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Grupo de Estoque
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor="description">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Descrição"
                control={control}
                fullWidth
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="supervisor">Responsável</InputLabel>
              <ControlledComboBox
                name="supervisor"
                control={control}
                placeholder="Responsável"
                errorMessage={errors.supervisor?.message?.toString()}
                selectOptions={users}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Compõe Geral:</Typography>
              <ControlledCheckbox name="include_overall" label={watchIncludeOverall ? 'Sim' : 'Não'} control={control} />
            </Grid>
          </Grid>
          <Divider sx={{ marginY: 2 }} />
          <Typography variant="body1" color="secondary" sx={{ marginBottom: 2 }} gutterBottom>
            Almoxarifados:
          </Typography>
          {linkedWarehouses.map((warehouse, index) => (
            <div key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={11}>
                  <AutocompleteSearch
                    value={selectedWarehouses[index] || {}}
                    defaultValue={
                      warehouse
                        ? {
                            id: warehouse.id,
                            name: `${warehouse.code} - ${warehouse.description}`
                          }
                        : undefined
                    }
                    onSearch={fetchWarehouse}
                    selectOptions={warehouses}
                    onSelect={(event, value: SelectOption) => handleSelectWarehouse(value, index)}
                    getOptionLabel={(option) => option.name!}
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <IconButton onClick={() => handleDeleteLinkedRepresentative(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 2 }} />
            </div>
          ))}
          <Grid item sm={12}>
            <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleAddLinkedRepresentative}>
              Adicionar Almoxarifado
            </Button>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
