import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { InventoryGroup } from '../../../models/InventoryGroup';

interface Props {
  item: InventoryGroup;
  onClose: () => void;
}

export const InventoryGroupDetailsDialog = ({ item, onClose }: Props) => {
  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes do Grupo de Estoque
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Descrição:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Supervisor:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.supervisor ? `${item.supervisor.first_name} ${item.supervisor.last_name}` : '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Compõe Geral:
            </Typography>
            <>{item.include_overall ? <Alert severity="success">Sim</Alert> : <Alert severity="error">Não</Alert>}</>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Almoxarifados:
            </Typography>
            <Typography variant="body1" gutterBottom>
              <ul>
                {item.warehouses?.map((d) => (
                  <li>
                    {d.code} - {d.description}
                  </li>
                ))}
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
