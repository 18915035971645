import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { InventoryGroup } from '../models/InventoryGroup';
import { InventoryGroupFilterFields } from '../sections/@sales-channel-representative/context/InventoryGroupContext';

const InventoryGroupUrl = '/inventory-analysis/inventory-group/';

export const InventoryGroupService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(InventoryGroupUrl);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: InventoryGroupFilterFields, page: number = 0, page_size: number = 0) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(InventoryGroupUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: InventoryGroup) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(InventoryGroupUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: InventoryGroup) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${InventoryGroupUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${InventoryGroupUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${InventoryGroupUrl}batch-delete/`, {
        ids: ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
