// project import
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import 'dayjs/locale/pt-br';
import ScrollTop from './components/@extended/ScrollTop';
import { GlobalSpinner } from './components/basics/GlobalSpinner';
import { SnackbarAlertComponent } from './components/basics/SnackbarAlertComponent';
import { AuthContextProvider } from './context/AuthContextProvider';
import { Routes } from './routes';

import ThemeCustomization from './themes';

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-BR">
      <ThemeCustomization>
        <ScrollTop>
          <AuthContextProvider>
            <GlobalSpinner />
            <SnackbarAlertComponent />
            <Routes />
          </AuthContextProvider>
        </ScrollTop>
      </ThemeCustomization>
    </LocalizationProvider>
  );
};

export default App;
