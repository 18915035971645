import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { StyledTableRow } from '../../../../components/basics/StyledTableRow';
import { PermissionsObj } from '../../../../hooks/useCrudPermissions';
import { IndicatorValues } from '../../../../models/IndicatorValues';
import { hideSpinner, showSpinner } from '../../../../store/slicers/globalSpinner.slicer';
import { formatCurrency, formatDate } from '../../../../utils/utils';
import { useCommercialDashboardsPageContext } from '../../context/CommercialDashboardContext';

interface Props {
  permissions: PermissionsObj;
  selectedRow: IndicatorValues;
  onClose: () => void;
}

export const CommercialDashboardValuesTable = ({ onClose, permissions, selectedRow }: Props) => {
  const dispatch = useDispatch();

  const { indicatorValues, fetchIndicatorValues, analysisType, indicatorValuesTotals } = useCommercialDashboardsPageContext();
  const [loading, setLoading] = useState<boolean>(false);
  const showNoteColumns = analysisType == 'N' ? true : false;
  useEffect(() => {
    const loadData = async () => {
      await fetchIndicatorValues(
        selectedRow?.sales_channel?.id ? selectedRow?.sales_channel?.id : selectedRow?.sales_channel,
        selectedRow?.representative,
        selectedRow?.type
      );
    };
    setLoading(true);
    dispatch(showSpinner());
    loadData();
    setLoading(false);
    dispatch(hideSpinner());
  }, [selectedRow]);

  return (
    <Dialog open={!loading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth maxWidth="md">
      <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <DialogTitle variant="h4" color="primary">
          Detalhes
        </DialogTitle>
        <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
          {onClose && (
            <IconButton sx={{ mr: 2 }} color="primary" onClick={onClose} size="small">
              <Close fontSize="small" />
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Divider />
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
                <TableHead>
                  <TableRow sx={{ backgroundColor: '#c1c1c1' }}>
                    {analysisType == 'N' && (
                      <>
                        <TableCell>
                          <Typography variant="h5" align="left" sx={{ textDecoration: 'underline' }}>
                            NUMERO
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                            DATA EMISSAO
                          </Typography>
                        </TableCell>
                      </>
                    )}
                    <TableCell align={analysisType == 'N' || analysisType == 'E' ? 'center' : 'left'}>
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        CÓDIGO
                      </Typography>
                    </TableCell>
                    {analysisType != 'E' && (
                      <TableCell align="center">
                        <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                          DESCRIÇÃO
                        </Typography>
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        VALOR
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {indicatorValues && indicatorValues?.count > 0 ? (
                    <>
                      {indicatorValues?.results?.results?.map((row: IndicatorValues, index: number) => {
                        return (
                          <StyledTableRow role="checkbox" tabIndex={-1} key={index} sx={{ cursor: 'pointer' }}>
                            {showNoteColumns && (
                              <>
                                <TableCell align="left">{Number(row.note_number)}</TableCell>
                                <TableCell align="center">{formatDate(row.emssion_date) || '-'}</TableCell>
                              </>
                            )}
                            <TableCell align={showNoteColumns ? 'center' : 'left'}>{row.code}</TableCell>
                            {analysisType != 'E' && <TableCell align="center">{row.description}</TableCell>}
                            <TableCell align="right">{formatCurrency(row.value)}</TableCell>
                          </StyledTableRow>
                        );
                      })}
                    </>
                  ) : (
                    <StyledTableRow>
                      <TableCell align="center" colSpan={5}>
                        Nenhum registro encontrado
                      </TableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
                {indicatorValuesTotals && (
                  <TableFooter>
                    <TableRow>
                      <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000' }}>
                        TOTAL
                      </TableCell>
                      {analysisType != 'E' && <TableCell colSpan={showNoteColumns ? 3 : 1} sx={{ backgroundColor: '#e0e0e0' }} />}
                      <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000' }}>
                        {formatCurrency(indicatorValuesTotals.value_total) || '-'}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                )}
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
