import { HelmetMeta } from '../../../layout/HelmetMeta';
import { InventoryGoalPageContextProvider } from '../../../sections/@inventory-goal/context/InventoryGoalContext';
import { InventoryGoalPage } from '../../../sections/@inventory-goal/InventoryGoalPage';

export const InventoryGoalPageWrapper = () => (
  <InventoryGoalPageContextProvider>
    <HelmetMeta pageName="Metas de Estoque" />
    <InventoryGoalPage />
  </InventoryGoalPageContextProvider>
);
