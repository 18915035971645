import { lazy } from 'react';
import { Loadable } from '../components/basics/LazyLoaderComponent/Loadable';
import MainLayout from '../layout/MainLayout';
import { AvailabilityPageWrapper } from '../pages/private/Availability';
import { AvailabilityValuePageWrapper } from '../pages/private/AvailabilityValue';
import { CommercialDashboardPageWrapper } from '../pages/private/CommercialDashboard';
import { CommercialIndicatorPageWrapper } from '../pages/private/CommercialIndicators';
import { CompanyParametersPageWrapper } from '../pages/private/CompanyParameters';
import { ExceedTargetPageWrapper } from '../pages/private/ExceedTarget';
import { IntegrationLogPageWrapper } from '../pages/private/IntegrationLog';
import { InventoryGoalPageWrapper } from '../pages/private/InventoryGoal';
import { InventoryGroupPageWrapper } from '../pages/private/InventoryGroup';
import { LinkCustomerPageWrapper } from '../pages/private/LinkCustomer';
import { LinkProductPageWrapper } from '../pages/private/LinkProduct';
import { LinkRepresentativePageWrapper } from '../pages/private/LinkRepresentative';
import { LinkSaleDivisionPageWrapper } from '../pages/private/LinkSaleDivision';
import { LinkSalesChannelPageWrapper } from '../pages/private/LinkSalesChannel';
import { PaymentOrderPageWrapper } from '../pages/private/PaymentOrder';
import { PermissionGroupsPageWrapper } from '../pages/private/PermissionGroups';
import { PositionAvailabilityValueWrapper } from '../pages/private/PositionAvailabilityValue';
import { ProfilePageWrapper } from '../pages/private/Profile';
import ReportPageWrapper from '../pages/private/Reports';
import { RepresentativeUserPageWrapper } from '../pages/private/RepresentativeUser';
import { SalesChannelPageWrapper } from '../pages/private/SalesChannel';
import { SalesChannelRepresentativePageWrapper } from '../pages/private/SalesChannelRepresentative';
import { UsersPageWrapper } from '../pages/private/Users';
import { ROUTES } from './routes';

const DashboardDefault = Loadable(lazy(() => import('../pages/private/Dashboard')));

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: ROUTES.authenticated.dashboard.home,
      element: <DashboardDefault />
    },
    {
      path: ROUTES.authenticated.user.profile,
      element: <ProfilePageWrapper />
    },
    {
      path: ROUTES.authenticated.access.users,
      element: <UsersPageWrapper />
    },
    {
      path: ROUTES.authenticated.access.permissionGroups,
      element: <PermissionGroupsPageWrapper />
    },
    {
      path: ROUTES.authenticated.auxiliary.parameters,
      element: <CompanyParametersPageWrapper />
    },
    {
      path: ROUTES.authenticated.integration.link_representative,
      element: <LinkRepresentativePageWrapper />
    },
    {
      path: ROUTES.authenticated.integration.link_sale_division,
      element: <LinkSaleDivisionPageWrapper />
    },
    {
      path: ROUTES.authenticated.integration.link_customer,
      element: <LinkCustomerPageWrapper />
    },
    {
      path: ROUTES.authenticated.integration.link_sales_channel,
      element: <LinkSalesChannelPageWrapper />
    },
    {
      path: ROUTES.authenticated.finance.payment_order,
      element: <PaymentOrderPageWrapper />
    },
    {
      path: ROUTES.authenticated.finance.availability,
      element: <AvailabilityPageWrapper />
    },
    {
      path: ROUTES.authenticated.finance.availability_value,
      element: <AvailabilityValuePageWrapper />
    },
    {
      path: ROUTES.authenticated.finance.position_availability_value,
      element: <PositionAvailabilityValueWrapper />
    },
    {
      path: ROUTES.authenticated.integration.integration_log,
      element: <IntegrationLogPageWrapper />
    },
    {
      path: ROUTES.authenticated.integration.link_product,
      element: <LinkProductPageWrapper />
    },
    {
      path: ROUTES.authenticated.report.financial,
      element: <ReportPageWrapper />
    },
    {
      path: ROUTES.authenticated.commercial.exceed_target,
      element: <ExceedTargetPageWrapper />
    },
    {
      path: ROUTES.authenticated.commercial.sales_channel_representative,
      element: <SalesChannelRepresentativePageWrapper />
    },
    {
      path: ROUTES.authenticated.commercial.representative_user,
      element: <RepresentativeUserPageWrapper />
    },
    {
      path: ROUTES.authenticated.commercial.sales_channel,
      element: <SalesChannelPageWrapper />
    },
    {
      path: ROUTES.authenticated.commercial.commercial_indicators,
      element: <CommercialIndicatorPageWrapper />
    },
    {
      path: ROUTES.authenticated.commercial.commercial_dashboard,
      element: <CommercialDashboardPageWrapper />
    },
    {
      path: ROUTES.authenticated.invetory_analysis.invertory_goals,
      element: <InventoryGoalPageWrapper />
    },
    {
      path: ROUTES.authenticated.invetory_analysis.invertory_group,
      element: <InventoryGroupPageWrapper />
    }
  ]
};

export default MainRoutes;
