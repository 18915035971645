import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { SalesChannel } from '../models/SalesChannel';

const SalesChannelUrl = '/commercial/sales-channel/';

export interface SalesChannelLinkFilterFields extends SalesChannel {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export const SalesChannelService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(SalesChannelUrl, {
        params: {
          page_size: 0
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },
  get: async (filterFields: SalesChannelLinkFilterFields, page: number = 0, page_size: number = 0) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(SalesChannelUrl, {
        params: {
          ...filterFields
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: SalesChannel) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(SalesChannelUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: SalesChannel) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${SalesChannelUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${SalesChannelUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${SalesChannelUrl}batch-delete/`, {
        ids: ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
