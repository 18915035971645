import { HelmetMeta } from '../../../layout/HelmetMeta';
import { SalesChannelRepresentativePageContextProvider } from '../../../sections/@sales-channel-representative/context/SalesChannelRepresentativeContext';
import { SalesChannelRepresentativePage } from '../../../sections/@sales-channel-representative/SalesChannelRepresentativePage';

export const SalesChannelRepresentativePageWrapper = () => (
  <SalesChannelRepresentativePageContextProvider>
    <HelmetMeta pageName="Canais de Venda x Representantes" />
    <SalesChannelRepresentativePage />
  </SalesChannelRepresentativePageContextProvider>
);
