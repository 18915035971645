import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { RepresentativeType } from '../models/RepresentativeType';

const RepresentativeTypeUrl = '/erp/representative-types/';

export interface RepresentativeTypeFilterFields extends RepresentativeType {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export const RepresentativeTypeService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(RepresentativeTypeUrl, {
        params: {
          page_size: 0
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },
  get: async (filterFields: RepresentativeTypeFilterFields, page: number = 0, page_size: number = 0) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(RepresentativeTypeUrl, {
        params: {
          ...filterFields
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
