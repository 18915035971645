import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { CommercialIndicatorField } from '../models/CommercialIndicatorField';

const CommercialIndicatorFieldUrl = '/commercial/commercial-indicator-field/';

export interface CommercialIndicatorFieldFilterFields extends CommercialIndicatorField {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export const CommercialIndicatorFieldService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(CommercialIndicatorFieldUrl, {
        params: {
          page_size: 0
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: CommercialIndicatorFieldFilterFields, page: number = 0, page_size: number = 0) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(CommercialIndicatorFieldUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: CommercialIndicatorField) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(CommercialIndicatorFieldUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: CommercialIndicatorField) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${CommercialIndicatorFieldUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${CommercialIndicatorFieldUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${CommercialIndicatorFieldUrl}batch-delete/`, {
        ids: ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
