// assets
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
export const InventoryAnalysesRoutes: INavGroup = {
  id: 'group-inventory-analysis',
  title: 'Análise de Estoque',
  icon: <Inventory2OutlinedIcon />,
  type: 'group',
  module: 'INVENTORY_ANALYSIS',
  level: 1,
  children: [
    {
      id: 'inventory_goals',
      title: 'Metas de Estoque',
      type: 'item',
      url: ROUTES.authenticated.invetory_analysis.invertory_goals,
      icon: <InventoryOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'INVENTORY_GOAL'
    },
    {
      id: 'inventory_group',
      title: 'Grupos de Estoque',
      type: 'item',
      url: ROUTES.authenticated.invetory_analysis.invertory_group,
      icon: <HolidayVillageOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'INVENTORY_GROUP'
    }
  ]
};
