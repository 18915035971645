import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SelectOption } from '../../../components/basics/ControlledComboBox';
import { ApiResults } from '../../../models/ApiResults';
import { CommercialDashboard, CommercialDashboardDetails } from '../../../models/CommercialDashboard';
import { GenerateIndicators } from '../../../models/GenerateIndicators';
import { IndicatorDetail, IndicatorDetailTotals } from '../../../models/IndicatorDetail';
import { IndicatorGrouped, IndicatorGroupedTotals } from '../../../models/IndicatorGrouped';
import { IndicatorTarget } from '../../../models/IndicatorTarget';
import { IndicatorValues, IndicatorValuesTotals } from '../../../models/IndicatorValues';
import { GenerateIndicatorsService } from '../../../services/GenerateInidicators.service';
import { IndicatorDetailService } from '../../../services/IndicatorDetail.service';
import { IndicatorGroupedService } from '../../../services/IndicatorGrouped.service';
import { IndicatorTargetService } from '../../../services/IndicatorTarget.service';
import { IndicatorValuesService } from '../../../services/IndicatorValues.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { formatSimpleDate } from '../../../utils/utils';

export interface CommercialDashboardFilterFields extends CommercialDashboard {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  item?: SelectOption | number;
  customer?: SelectOption | number;
  company?: SelectOption | number;
  sale_division?: SelectOption | number;
  market_segment?: SelectOption | number;
  representative?: SelectOption | number;
  start_date?: string;
  end_date?: string;
  show_comparison?: boolean;
  analytical_query?: string;
  indicator: number;
}

export interface IndicatorGroupedFilterFields extends IndicatorGrouped {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  session: string;
}

export interface IndicatorTargetFilterFields extends IndicatorTarget {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  session: string;
}

export interface IndicatorDetailFilterFields extends IndicatorDetail {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  session: string;
}

export interface IndicatorValuesFilterFields extends IndicatorValues {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  session: string;
}

export interface SessionResponse {
  finished: number;
  session: string;
}

export interface CommercialDashboardResult extends ApiResults {
  results: CommercialDashboard[];
}
export interface IndicatorGroupedResult extends ApiResults {
  results: IndicatorGrouped[];
  totals: IndicatorGroupedTotals;
}

export interface IndicatorTargetResult extends ApiResults {
  results: IndicatorTarget[];
}

export interface IndicatorDetailResult extends ApiResults {
  results: IndicatorDetail[];
  totals: IndicatorDetailTotals;
}

export interface IndicatorValuesResult extends ApiResults {
  results: IndicatorValues[];
  totals: IndicatorValuesTotals;
}

export interface CommercialDashboardDetailsResult extends ApiResults {
  results: CommercialDashboardDetails[];
}

interface CommercialDashboardContextProps {
  indicatorGrouped: IndicatorGroupedResult | undefined;
  indicatorGroupedTotals: IndicatorGroupedTotals | undefined;
  indicatorTarget: IndicatorTargetResult | undefined;
  indicatorValues: IndicatorValuesResult | undefined;
  indicatorDetail: IndicatorDetailResult | undefined;
  indicatorDetailTotals: IndicatorDetailTotals | undefined;
  indicatorValuesTotals: IndicatorValuesTotals | undefined;
  fetchIndicatorGrouped: () => void;
  fetchIndicatorDetails: (sales_channel: number) => void;
  fetchIndicatorValues: (sales_channel: number, representative: number, type: string) => void;
  showComparison: boolean;
  setShowComparison: React.Dispatch<React.SetStateAction<boolean>>;
  analysisType: AnalysisType;
  setAnalysisType: React.Dispatch<React.SetStateAction<AnalysisType>>;
  filterFields: CommercialDashboardFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<CommercialDashboardFilterFields>>;
}

const CommercialDashboardsPageContext = createContext<CommercialDashboardContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

type AnalysisType = 'C' | 'I' | 'N' | 'E';

export const CommercialDashboardPageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [sessionProc, setSessionProc] = useState<SessionResponse | undefined>(undefined);
  const [indicatorGrouped, setIndicatorGrouped] = useState<IndicatorGroupedResult | undefined>(undefined);
  const [indicatorGroupedTotals, setIndicatorGroupedTotals] = useState<IndicatorGroupedTotals | undefined>(undefined);
  const [indicatorTarget, setIndicatorTarget] = useState<IndicatorTargetResult | undefined>(undefined);
  const [indicatorDetail, setIndicatorDetail] = useState<IndicatorDetailResult | undefined>(undefined);
  const [indicatorDetailTotals, setIndicatorDetailTotals] = useState<IndicatorDetailTotals | undefined>(undefined);
  const [indicatorValues, setIndicatorValues] = useState<IndicatorValuesResult | undefined>(undefined);
  const [indicatorValuesTotals, setInidicatorValuesTotals] = useState<IndicatorValuesTotals | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<CommercialDashboardFilterFields>({} as CommercialDashboardFilterFields);
  const [showComparison, setShowComparison] = useState<boolean>(false);
  const [analysisType, setAnalysisType] = useState<AnalysisType | null>(null);

  const fetchIndicatorGrouped = async () => {
    try {
      const response = await IndicatorGroupedService.get({ session: sessionProc?.session });
      if (checkResponseStatus(response)) {
        setIndicatorGrouped(response?.data);
        setIndicatorGroupedTotals(response?.data?.results?.totals);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    }
  };

  const fetchIndicatorTarget = async () => {
    try {
      const response = await IndicatorTargetService.get({ session: sessionProc?.session });
      if (checkResponseStatus(response)) {
        setIndicatorTarget(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    }
  };

  const fetchIndicatorDetails = async (sales_channel: number) => {
    try {
      const response = await IndicatorDetailService.get({ sales_channel: sales_channel, session: sessionProc?.session });
      if (checkResponseStatus(response)) {
        setIndicatorDetail(response?.data);
        setIndicatorDetailTotals(response?.data?.results?.totals);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    }
  };

  const fetchIndicatorValues = async (sales_channel: number, representative: number, type: string) => {
    try {
      const response = await IndicatorValuesService.get({
        sales_channel: sales_channel,
        representative: representative,
        type: type,
        session: sessionProc?.session
      });
      if (checkResponseStatus(response)) {
        setIndicatorValues(response?.data);
        setInidicatorValuesTotals(response?.data?.results?.totals);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    }
  };

  const generateIndicators = async () => {
    try {
      const payload = {
        pi_indicador_id: filterFields?.indicator,
        pi_empr_id: filterFields?.company?.id || null,
        pi_cliente: filterFields?.customer?.id || null,
        pi_div_venda: filterFields?.sale_division?.id || null,
        pi_segmento: filterFields?.market_segment?.id || null,
        pi_representante: filterFields?.representative?.id || null,
        pi_item: filterFields?.item?.id || null,
        pi_analitico: filterFields?.analytical_query,
        pi_compara: filterFields?.show_comparison,
        pi_periodo: formatSimpleDate(filterFields?.start_date!) + '|' + formatSimpleDate(filterFields?.end_date!)
      } as GenerateIndicators;

      const response = await GenerateIndicatorsService.create(payload);

      if (checkResponseStatus(response)) {
        setSessionProc(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    }
  };

  useEffect(() => {
    const loadData = async () => {
      dispatch(showSpinner());

      await generateIndicators();
      dispatch(hideSpinner());
    };

    if (Object.keys(filterFields).length > 0) {
      loadData();
    }
  }, [filterFields]);

  useEffect(() => {
    const fetchData = async () => {
      if (sessionProc) {
        await Promise.all([fetchIndicatorGrouped(), fetchIndicatorTarget()]);
      }
    };

    fetchData();
  }, [sessionProc]);

  const contextValue: CommercialDashboardContextProps = {
    indicatorGrouped,
    indicatorGroupedTotals,
    indicatorTarget,
    indicatorValues,
    indicatorValuesTotals,
    indicatorDetail,
    indicatorDetailTotals,
    fetchIndicatorGrouped,
    fetchIndicatorDetails,
    fetchIndicatorValues,
    showComparison,
    setShowComparison,
    analysisType,
    setAnalysisType,
    filterFields,
    setFilterFields
  };

  return <CommercialDashboardsPageContext.Provider value={contextValue}>{children}</CommercialDashboardsPageContext.Provider>;
};

export const useCommercialDashboardsPageContext = () => {
  const context = useContext(CommercialDashboardsPageContext);
  if (!context) {
    throw new Error('useCommercialDashboardsPageContext must be used within a CommercialDashboardsPageContextProvider');
  }
  return context;
};
