import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { GenerateIndicators } from '../models/GenerateIndicators';

const GenerateIndicatorsUrl = '/erp/generate-indicators/';

export const GenerateIndicatorsService = {
  create: async (data: GenerateIndicators) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(GenerateIndicatorsUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
