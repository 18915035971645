import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useCrudPermissions } from '../../../hooks/useCrudPermissions';
import { CommercialIndicator } from '../../../models/CommercialIndicator';
import { CommercialIndicatorDetailsDialog } from '../CommercialIndicatorDetailsDialog';
import { CommercialIndicatorFilterDialog } from '../CommercialIndicatorFilterDialog';
import { CommercialIndicatorFormDialog } from '../CommercialIndicatorFormDialog';
import { CommercialIndicatorTable } from '../CommercialIndicatorTable';
import { useCommercialIndicatorsPageContext } from '../context/CommercialIndicatorContext';
import { ButtonsContainer } from './styles';

export const CommercialIndicatorPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'COMMERCIAL_INDICATORS'
  });

  const [showFilter, setShowFilter] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [editItem, setEditItem] = useState<CommercialIndicator | undefined>(undefined);
  const [viewItem, setViewItem] = useState<CommercialIndicator | undefined>(undefined);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields } = useCommercialIndicatorsPageContext();

  const handleAddUser = () => {
    setNewItem(true);
  };

  const handleViewItem = (item: CommercialIndicator) => {
    setViewItem(item);
  };

  const handleEditItem = (item: CommercialIndicator) => {
    setEditItem(item);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setNewItem(false);
    setEditItem(undefined);
    setViewItem(undefined);
  };

  useEffect(() => {
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 500);
      debounced();
      return debounced.cancel;
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <>
            <Tooltip title="Filtros">
              <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
                Filtros
              </Button>
            </Tooltip>
          </>
        )}
        {permissions.hasCreatePermission && (
          <Tooltip title="Criar Indicador">
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddUser}>
              Adicionar Indicador
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      <CommercialIndicatorTable onView={handleViewItem} onEdit={handleEditItem} permissions={permissions} />
      {showFilter && permissions.hasReadPermission && <CommercialIndicatorFilterDialog onClose={handleCloseDialogs} />}
      {viewItem && <CommercialIndicatorDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
      {(newItem || editItem) && permissions.hasCreatePermission && (
        <CommercialIndicatorFormDialog editItem={editItem} onClose={handleCloseDialogs} />
      )}
    </>
  );
};
