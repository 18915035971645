import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { CompanyERP } from '../../../models/CompanyERP';
import { Customer } from '../../../models/Customer';
import { Item } from '../../../models/Item';
import { MarketSegment } from '../../../models/MarketSegment';
import { Representative } from '../../../models/Representative';
import { SaleDivision } from '../../../models/SaleDivision';
import { CompanyERPService } from '../../../services/CompanyERP.service';
import { CustomerService } from '../../../services/Customer.service';
import { ItemService } from '../../../services/Item.service';
import { MarketSegmentService } from '../../../services/MarketSegment.service';
import { RepresentativesService } from '../../../services/Representative.service';
import { SaleDivisionService } from '../../../services/SaleDivision.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { IndicatorFilterSchema } from '../../../utils/forms/validations/formValidations';
import { findSelectedOption } from '../../../utils/utils';
import { CommercialDashboardFilterFields, useCommercialDashboardsPageContext } from '../context/CommercialDashboardContext';

interface Props {
  onClose: () => void;
  indicatorId: number;
  defaultCompany: CompanyERP | null;
}

export const filterShowComparisons: SelectOption[] = [
  { id: 'S', name: 'Sim' },
  { id: 'N', name: 'Não' }
];

export const filterAnalyticalQuery: SelectOption[] = [
  { id: 'C', name: 'Curva ABC de Clientes' },
  { id: 'I', name: 'Curva ABC de Itens' },
  { id: 'N', name: 'Detalhamento de Notas' },
  { id: 'E', name: 'Detalhamento por UF' }
];

const getFirstAndLastOfCurrentMonth = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');

  const firstDay = `${year}-${month}-01`;

  const lastDay = new Date(year, today.getMonth() + 1, 0);
  const formattedLastDay = `${year}-${month}-${String(lastDay.getDate()).padStart(2, '0')}`;

  return { firstDay, lastDay: formattedLastDay };
};

const getDefaultValues = (filter?: CommercialDashboardFilterFields): CommercialDashboardFilterFields => {
  const { firstDay, lastDay } = getFirstAndLastOfCurrentMonth();
  return {
    show_comparison: filter?.show_comparison ? findSelectedOption(filterShowComparisons, filter?.show_comparison) : null,
    analytical_query: filter?.analytical_query ? findSelectedOption(filterAnalyticalQuery, filter?.analytical_query) : null,
    start_date: filter?.start_date || firstDay,
    end_date: filter?.end_date || lastDay
  };
};

export const CommercialDashboardFilterDialog = ({ onClose, indicatorId, defaultCompany }: Props) => {
  const dispatch = useDispatch();
  const { filterFields, setFilterFields, setShowComparison, setAnalysisType } = useCommercialDashboardsPageContext();
  const { user: userAuth } = useAuthContext();
  const [selectedItemERP, setSelectedItemERP] = useState<SelectOption>(filterFields?.item || ({} as SelectOption));
  const [itensERP, setItensERP] = useState<SelectOption[]>([]);
  const defaultRepresentative = userAuth?.representative_linked?.id
    ? {
        id: userAuth?.representative_linked?.id,
        name: `${userAuth?.representative_linked?.code} - ${userAuth?.representative_linked?.description}`
      }
    : null;

  const [selectedCustomerERP, setSelectedCustomerERP] = useState<SelectOption>(filterFields?.customer || ({} as SelectOption));
  const [customersERP, setCustomersERP] = useState<SelectOption[]>([]);

  const [selectedCompanyERP, setSelectedCompanyERP] = useState<SelectOption>(
    filterFields?.company ??
      (defaultCompany
        ? {
            id: defaultCompany.id,
            name: `${defaultCompany.code} - ${defaultCompany.name}`,
            company_code: defaultCompany.code
          }
        : ({} as SelectOption))
  );
  const [companyERP, setCompanyERP] = useState<SelectOption[]>([]);

  const [selectedSaleDivisionERP, setSelectedSaleDivisionERP] = useState<SelectOption>(filterFields?.sale_division || ({} as SelectOption));
  const [saleDivisionERP, setSaleDivisionERP] = useState<SelectOption[]>([]);

  const [selectedMarketSegmentERP, setSelectedMarketSegmentERP] = useState<SelectOption>(
    filterFields?.market_segment || ({} as SelectOption)
  );
  const [marketSegmentERP, setMarketSegmentERP] = useState<SelectOption[]>([]);

  const [selectedRepresentativeERP, setSelectedRepresentativeERP] = useState<SelectOption>(
    defaultRepresentative || filterFields?.representative || ({} as SelectOption)
  );
  const [representativeERP, setRepresentativeERP] = useState<SelectOption[]>([]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    formState: { errors }
  } = useForm<CommercialDashboardFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(IndicatorFilterSchema),
    defaultValues: getDefaultValues(filterFields)
  });

  const fetchItemERP = async (watchItemERPSearch?: string) => {
    try {
      const response = await ItemService.get({
        search: watchItemERPSearch,
        page_size: 0
      });
      if (response?.data?.results) {
        const dataItemERP = response?.data?.results;
        setItensERP(
          dataItemERP.map((item: Item) => ({
            id: item.id,
            name: item.description,
            code: item.code
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Itens ERP',
          severity: 'error'
        })
      );
    }
  };

  const fetchCompanyERP = async (watchCompanyERPSearch?: string) => {
    try {
      const response = await CompanyERPService.get({
        search: watchCompanyERPSearch,
        page_size: 0
      });
      if (response?.data?.results) {
        const dataCompanyERP = response?.data?.results;
        setCompanyERP(
          dataCompanyERP.map((item: CompanyERP) => ({
            id: item.id,
            name: `${item.code} - ${item.name}`,
            code: item.code
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Empresas ERP',
          severity: 'error'
        })
      );
    }
  };

  const fetchCustomerERP = async (watchCustomerSearch?: string) => {
    try {
      const response = await CustomerService.get({
        search: watchCustomerSearch,
        page_size: 0
      });
      if (response?.data?.results) {
        const dataCustomerERP = response?.data?.results;
        setCustomersERP(
          dataCustomerERP.map((customer: Customer) => ({
            id: customer.id,
            name: customer.name
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Clientes ERP',
          severity: 'error'
        })
      );
    }
  };

  const fetchSaleDivisionERP = async (watchSaleDivisionSearch?: string) => {
    try {
      const response = await SaleDivisionService.get({
        search: watchSaleDivisionSearch,
        page_size: 0
      });
      if (response?.data?.results) {
        const dataSaleDivisionERP = response?.data?.results;
        setSaleDivisionERP(
          dataSaleDivisionERP.map((sale_division: SaleDivision) => ({
            id: sale_division.id,
            name: sale_division.description,
            code: sale_division.code
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Divisões de Venda ERP',
          severity: 'error'
        })
      );
    }
  };

  const fetchMarketSegmentERP = async (watchMarketSegmentSearch?: string) => {
    try {
      const response = await MarketSegmentService.get({
        search: watchMarketSegmentSearch,
        page_size: 0
      });
      if (response?.data?.results) {
        const dataMarketSegmentERP = response?.data?.results;
        setMarketSegmentERP(
          dataMarketSegmentERP.map((market_segment: MarketSegment) => ({
            id: market_segment.id,
            name: market_segment.description,
            code: market_segment.code
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Segmentos ERP',
          severity: 'error'
        })
      );
    }
  };

  const fetchRepresentativeERP = async (watchRepresentativeSearch?: string) => {
    try {
      const response = await RepresentativesService.get({
        search: watchRepresentativeSearch,
        page_size: 0
      });
      if (response?.data?.results) {
        const dataRepresentativeERP = response?.data?.results;
        setRepresentativeERP(
          dataRepresentativeERP.map((representative: Representative) => ({
            id: representative.id,
            name: representative.description,
            code: representative.code
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Representantes ERP',
          severity: 'error'
        })
      );
    }
  };

  const handleSelectItemERP = (value: SelectOption) => {
    setSelectedItemERP(value);
  };

  const handleSelectCustomerERP = (value: SelectOption) => {
    setSelectedCustomerERP(value);
  };

  const handleSelectSaleDivisionERP = (value: SelectOption) => {
    setSelectedSaleDivisionERP(value);
  };

  const handleSelectMarketSegmentERP = (value: SelectOption) => {
    setSelectedMarketSegmentERP(value);
  };

  const handleSelectRepresentativeERP = (value: SelectOption) => {
    setSelectedRepresentativeERP(value);
  };

  const handleSelectCompanyERP = (value: SelectOption) => {
    setSelectedCompanyERP(value);
  };

  const onSubmit: SubmitHandler<CommercialDashboardFilterFields> = async (data: CommercialDashboardFilterFields) => {
    if (!selectedCompanyERP?.id) {
      setError('company', {
        type: 'manual',
        message: 'O Empresa é obrigatoria'
      });
      return;
    }
    data = {
      ...data,
      analytical_query: data?.analytical_query?.id || null,
      show_comparison: data?.show_comparison?.id || null,
      start_date: data?.start_date || undefined,
      end_date: data?.end_date || undefined,
      company: selectedCompanyERP,
      customer: selectedCustomerERP,
      sale_division: selectedSaleDivisionERP,
      representative: selectedRepresentativeERP,
      market_segment: selectedMarketSegmentERP,
      item: selectedItemERP,
      indicator: indicatorId
    };

    setShowComparison(data?.show_comparison === 'S' ? true : false);
    setAnalysisType(data?.analytical_query);

    setFilterFields({ ...data } as CommercialDashboardFilterFields);
    onClose();
  };

  const handleClear = () => {
    setSelectedCompanyERP(
      defaultCompany
        ? { id: defaultCompany?.id, name: `${defaultCompany?.code} - ${defaultCompany?.name}`, company_code: defaultCompany?.code }
        : {}
    );
    setFilterFields({} as CommercialDashboardFilterFields);
    setShowComparison(false);
    setAnalysisType(null);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="company">Empresa</InputLabel>
              <AutocompleteSearch
                value={selectedCompanyERP}
                onSearch={fetchCompanyERP}
                selectOptions={companyERP}
                onSelect={(event, value: SelectOption) => handleSelectCompanyERP(value)}
                errorMessage={errors.company?.message}
                getOptionLabel={(option) => option.name!}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="customer">Cliente</InputLabel>
              <AutocompleteSearch
                value={selectedCustomerERP}
                onSearch={fetchCustomerERP}
                selectOptions={customersERP}
                onSelect={(event, value: SelectOption) => handleSelectCustomerERP(value)}
                errorMessage={errors.customer?.message}
                getOptionLabel={(option) => option.name!}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="sale_division">Divisão de Venda</InputLabel>
              <AutocompleteSearch
                value={selectedSaleDivisionERP}
                onSearch={fetchSaleDivisionERP}
                selectOptions={saleDivisionERP}
                onSelect={(event, value: SelectOption) => handleSelectSaleDivisionERP(value)}
                errorMessage={errors.sale_division?.message}
                getOptionLabel={(option) => option.name!}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="market_segment">Segmento</InputLabel>
              <AutocompleteSearch
                value={selectedMarketSegmentERP}
                onSearch={fetchMarketSegmentERP}
                selectOptions={marketSegmentERP}
                onSelect={(event, value: SelectOption) => handleSelectMarketSegmentERP(value)}
                errorMessage={errors.market_segment?.message}
                getOptionLabel={(option) => option.name!}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="representative">Representante</InputLabel>
              <AutocompleteSearch
                value={selectedRepresentativeERP}
                onSearch={fetchRepresentativeERP}
                selectOptions={representativeERP}
                onSelect={(event, value: SelectOption) => handleSelectRepresentativeERP(value)}
                errorMessage={errors.representative?.message}
                getOptionLabel={(option) => option.name!}
                disabled={defaultRepresentative ? true : false}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="item">Item</InputLabel>
              <AutocompleteSearch
                value={selectedItemERP}
                onSearch={fetchItemERP}
                selectOptions={itensERP}
                onSelect={(event, value: SelectOption) => handleSelectItemERP(value)}
                errorMessage={errors.item?.message}
                getOptionLabel={(option) => option.name!}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="start_date">Data Inicial</InputLabel>
              <ControlledTextInput
                name="start_date"
                placeholder="Data Inicial"
                type="date"
                control={control}
                errorMessage={errors.start_date?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="end_date">Data Final</InputLabel>
              <ControlledTextInput
                name="end_date"
                placeholder="Data Final"
                type="date"
                control={control}
                errorMessage={errors.end_date?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="analytical_query">Consulta Analítica</InputLabel>
              <ControlledComboBox
                name="analytical_query"
                control={control}
                errorMessage={errors.analytical_query?.message?.toString()}
                selectOptions={filterAnalyticalQuery}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="show_comparison">Exibir comparação Mês/Ano Anterior</InputLabel>
              <ControlledComboBox
                name="show_comparison"
                control={control}
                errorMessage={errors.show_comparison?.message?.toString()}
                selectOptions={filterShowComparisons}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
