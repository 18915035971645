import { TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { StyledTableRow } from '../../../../components/basics/StyledTableRow';
import { PermissionsObj } from '../../../../hooks/useCrudPermissions';
import { IndicatorGrouped } from '../../../../models/IndicatorGrouped';
import { SalesChannel } from '../../../../models/SalesChannel';
import { formatCurrency } from '../../../../utils/utils';
import { useCommercialDashboardsPageContext } from '../../context/CommercialDashboardContext';

interface Props {
  permissions: PermissionsObj;
  onRowClick: (row: IndicatorGrouped) => void;
  onCellClick: (sales_channel: SalesChannel, representative?: number | null, type: string) => void;
}

export const CommercialDashboardTable = ({ permissions, onRowClick, onCellClick }: Props) => {
  const { indicatorGrouped, indicatorGroupedTotals, showComparison, analysisType } = useCommercialDashboardsPageContext();
  return (
    <Box sx={{ width: '100%' }}>
      {indicatorGrouped ? (
        <Paper sx={{ width: '100%' }}>
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
              <TableHead>
                <TableRow sx={{ backgroundColor: '#c1c1c1' }}>
                  <TableCell>
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      CANAL DE VENDA
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      FATURAMENTO REALIZADO
                    </Typography>
                  </TableCell>
                  {showComparison && (
                    <TableCell align="right">
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        FATURAMENTO REALIZADO ANTERIOR
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      CARTEIRA
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      FATURAMENTO PREVISTO
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      META
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      META SUPERACAO
                    </Typography>
                  </TableCell>
                  {showComparison && (
                    <TableCell align="right">
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        META ANTERIOR
                      </Typography>
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                      TOTAL
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {indicatorGrouped?.count > 0 ? (
                  indicatorGrouped.results.results.map((row: IndicatorGrouped) => (
                    <StyledTableRow role="checkbox" tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }} onClick={() => onRowClick(row)}>
                      <TableCell align="left" sx={{ backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                        {row.sales_channel_description?.toLocaleUpperCase()}
                      </TableCell>
                      <TableCell align="right" onClick={analysisType ? () => onCellClick(row?.sales_channel, null, 'NFS') : undefined}>
                        {formatCurrency(row.realized_revenue)}
                      </TableCell>
                      {showComparison && <TableCell align="right">{formatCurrency(row.realized_revenue_previous)}</TableCell>}
                      <TableCell align="right" onClick={analysisType ? () => onCellClick(row?.sales_channel, null, 'PDV') : undefined}>
                        {formatCurrency(row.portfolio)}
                      </TableCell>
                      <TableCell align="right">{formatCurrency(row.forecasted_revenue)}</TableCell>
                      <TableCell align="right">{formatCurrency(row.target_value)}</TableCell>
                      <TableCell align="right">{formatCurrency(row.exceed_target_value)}</TableCell>
                      {showComparison && <TableCell align="right">{formatCurrency(row.target_value_previous)}</TableCell>}
                      <TableCell align="right">{formatCurrency(row.total)}</TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow>
                    <TableCell align="center" colSpan={showComparison ? 9 : 7}>
                      Nenhum registro encontrado
                    </TableCell>
                  </StyledTableRow>
                )}
              </TableBody>
              {indicatorGroupedTotals && (
                <TableFooter>
                  <TableRow>
                    <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000' }}>
                      TOTAIS
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000' }}>
                      {formatCurrency(indicatorGroupedTotals.realized_revenue_total)}
                    </TableCell>
                    {showComparison && (
                      <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000' }}>
                        {formatCurrency(indicatorGroupedTotals.realized_revenue_previous_total)}
                      </TableCell>
                    )}
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000' }}>
                      {formatCurrency(indicatorGroupedTotals.portfolio_total)}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000' }}>
                      {formatCurrency(indicatorGroupedTotals.forecasted_revenue_total)}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000' }}>
                      {formatCurrency(indicatorGroupedTotals.target_value_total)}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000' }}>
                      {formatCurrency(indicatorGroupedTotals.exceed_target_value_total)}
                    </TableCell>
                    {showComparison && (
                      <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000' }}>
                        {formatCurrency(indicatorGroupedTotals.target_value_previous_total)}
                      </TableCell>
                    )}
                    <TableCell align="right" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0', color: '#000' }}>
                      {formatCurrency(indicatorGroupedTotals.total_sum)}
                    </TableCell>
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Box sx={{ display: 'flex', width: '100%', p: 4, justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="h5" color="secondary">
            Selecione o filtro e aplique para buscar os dados.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
