import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { IndicatorGroupedFilterFields } from '../sections/@commercial-dashboard/context/CommercialDashboardContext';

const IndicatorGroupedUrl = '/erp/indicator-grouped/';

export const IndicatorGroupedService = {
  get: async (filterFields: IndicatorGroupedFilterFields, page: number = 1, page_size: number = 0) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(IndicatorGroupedUrl, {
        params: {
          ...filterFields,
          page_size: page_size,
          page: page
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
