import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { SalesChannelLink } from '../models/SalesChannelLink';
import { SalesChannelLinkFilterFields } from '../sections/@link-sales-channel/context/SalesChannelLinkPageContext';

const SalesChannelLinkUrl = '/rd-station/sales-channel-relationship/';

export const SalesChannelLinkService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(SalesChannelLinkUrl);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: SalesChannelLinkFilterFields, page: number = 0, page_size: number = 5) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(SalesChannelLinkUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: SalesChannelLink) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(SalesChannelLinkUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: SalesChannelLink) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${SalesChannelLinkUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${SalesChannelLinkUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${SalesChannelLinkUrl}batch-delete/`, {
        companies: ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
