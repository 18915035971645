import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { SalesChannelRepresentative } from '../models/SalesChannelRepresentative';
import { SalesChannelRepresentativeFilterFields } from '../sections/@sales-channel-representative/context/SalesChannelRepresentativeContext';

const SalesChannelRepresentativeUrl = '/commercial/sales-channel-representative/';

export const SalesChannelRepresentativeService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(SalesChannelRepresentativeUrl);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },
  
  get: async (filterFields: SalesChannelRepresentativeFilterFields, page: number = 0, page_size: number = 0) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(SalesChannelRepresentativeUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: SalesChannelRepresentative) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(SalesChannelRepresentativeUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: SalesChannelRepresentative) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${SalesChannelRepresentativeUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${SalesChannelRepresentativeUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${SalesChannelRepresentativeUrl}batch-delete/`, {
        ids: ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
