import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { FormMessage } from '../../../models/FormMessage';
import { InventoryGoal } from '../../../models/InventoryGoal';
import { Warehouse } from '../../../models/Warehouse';
import { InventoryGoalService } from '../../../services/InventoryGoal.service';
import { WarehouseService } from '../../../services/Warehouse.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { InventoryGoalScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useInventoryGoalsPageContext } from '../context/InventoryGoalContext';

interface Props {
  editItem?: InventoryGoal;
  onClose: () => void;
}

const getFirstDayOfCurrentMonth = () => {
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();
  return `${year}-${month}-01`;
};

export const InventoryGoalFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);

  const { fetchInventoryGoals } = useInventoryGoalsPageContext();

  const [warehouseERP, setWarehouseERP] = useState<SelectOption[]>([]);
  const [selectedWarehouseERP, setSelectedWarehouseERP] = useState<SelectOption>(
    editItem?.warehouse
      ? { id: editItem.warehouse.id, name: `${editItem.warehouse.code} - ${editItem.warehouse.description}` }
      : ({} as SelectOption)
  );

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors }
  } = useForm<InventoryGoal>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(InventoryGoalScheme),
    defaultValues: editItem
      ? {
          ...editItem,
          value: editItem.value?.replace('.', ',')
        }
      : {
          entry_date: getFirstDayOfCurrentMonth()
        }
  });
  const onSubmitForm: SubmitHandler<InventoryGoal> = async (data: InventoryGoal) => {
    data = {
      ...data,
      entry_date: data.entry_date,
      value: data.value.replace('R$ ', '').replace('.', '').replace(',', '.'),
      warehouse: selectedWarehouseERP?.id
    };
    dispatch(showSpinner());

    try {
      const response = editItem?.id ? await InventoryGoalService.update(data) : await InventoryGoalService.create(data);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Meta de Estoque atualizada com sucesso!' : 'Meta de Estoque criada com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchInventoryGoals();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleSelectWarehouseERP = (value: SelectOption) => {
    setSelectedWarehouseERP(value);
  };

  const fetchWarehouseERP = async (watchWarehouseERPSearch?: string) => {
    try {
      const response = await WarehouseService.get({
        search: watchWarehouseERPSearch
      });
      if (response?.data?.results) {
        const dataAlmoxERP = response?.data?.results;
        setWarehouseERP(
          dataAlmoxERP.map((almox: Warehouse) => ({
            id: almox.id,
            name: `${almox.code} - ${almox.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Almoxarifados ERP',
          severity: 'error'
        })
      );
    }
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Nova'} Meta de Estoque
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor="almox_id">Almoxarifado</InputLabel>
              <AutocompleteSearch
                value={selectedWarehouseERP}
                onSearch={fetchWarehouseERP}
                selectOptions={warehouseERP}
                onSelect={(event, value: SelectOption) => handleSelectWarehouseERP(value)}
                errorMessage={errors.warehouse?.message}
                getOptionLabel={(option) => option.name!}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel htmlFor="entry_date">Período</InputLabel>
              <ControlledTextInput
                name="entry_date"
                placeholder="Data"
                control={control}
                fullWidth
                type="date"
                errorMessage={errors.entry_date?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="value">Valor</InputLabel>
              <ControlledTextInput
                name="value"
                placeholder="Valor"
                control={control}
                errorMessage={errors.value?.message?.toString()}
                currency={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
