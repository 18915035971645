import { AccessRoutes } from './access';
import { CommercialRoutes } from './commercial';
import { DashboardRoutes } from './dashboard';
import { FinanceRoutes } from './finance';
import { IntegrationRoutes } from './integration';
import { InventoryAnalysesRoutes } from './inventory_analysis';
import { ReportRoutes } from './report';
import { SettingsRoutes } from './settings';

// ==============================|| MENU ITEMS ||============================== //

export const MenuItems = {
  items: [
    DashboardRoutes,
    AccessRoutes,
    FinanceRoutes,
    CommercialRoutes,
    InventoryAnalysesRoutes,
    IntegrationRoutes,
    SettingsRoutes,
    ReportRoutes
  ]
};
