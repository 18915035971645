import { HelmetMeta } from '../../../layout/HelmetMeta';
import { SalesChannelLinkPageContextProvider } from '../../../sections/@link-sales-channel/context/SalesChannelLinkPageContext';
import { SalesChannelLinkPage } from '../../../sections/@link-sales-channel/SalesChannelLinkPage';

export const LinkSalesChannelPageWrapper = () => (
  <SalesChannelLinkPageContextProvider>
    <HelmetMeta pageName="Vinculo de Canal de Venda" />
    <SalesChannelLinkPage />
  </SalesChannelLinkPageContextProvider>
);
