import { HelmetMeta } from '../../../layout/HelmetMeta';
import { SalesChannelPageContextProvider } from '../../../sections/@sales-channel/context/SalesChannelContext';
import { SalesChannelPage } from '../../../sections/@sales-channel/SalesChannelPage';

export const SalesChannelPageWrapper = () => (
  <SalesChannelPageContextProvider>
    <HelmetMeta pageName="Canais de Venda" />
    <SalesChannelPage />
  </SalesChannelPageContextProvider>
);
