import FilterListIcon from '@mui/icons-material/FilterList';
import { Autocomplete, Box, Button, TextField, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useCrudPermissions } from '../../../hooks/useCrudPermissions';
import { CommercialIndicator } from '../../../models/CommercialIndicator';
import { CompanyERP } from '../../../models/CompanyERP';
import { CommercialIndicatorService } from '../../../services/CommercialIndicator.service';
import { CompanyERPService } from '../../../services/CompanyERP.service';
import { DefaultCompanyParameterService } from '../../../services/DefaultCompanyParameter.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { CommercialDashboardCard } from '../CommercialDashboardCard';
import { CommercialDashboardFilterDialog } from '../CommercialDashboardFilterDialog';
import { useCommercialDashboardsPageContext } from '../context/CommercialDashboardContext';
import { ButtonsContainer } from './styles';

export const CommercialDashboardPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'COMMERCIAL_DASHBOARD'
  });

  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState(false);
  const [commercialIndicators, setCommercialIndicators] = useState<CommercialIndicator[]>([]);
  const [selectedIndicator, setSelectedIndicator] = useState<CommercialIndicator | null>(null);
  const [defaultCompany, setDefaultCompany] = useState<CompanyERP | null>(null);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields } = useCommercialDashboardsPageContext();

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
  };

  const fetchIndicatorFields = async () => {
    try {
      const response = await CommercialIndicatorService.getAll();
      if (response?.data?.results) {
        setCommercialIndicators(response.data.results);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    }
  };

  const fetchDefaultCompany = async () => {
    try {
      const response = await DefaultCompanyParameterService.get();
      if (checkResponseStatus(response) && response?.data) {
        setDefaultCompany(response?.data || null);
        const parameter = response?.data;
        const responseCompany = await CompanyERPService.get({
          code: parameter?.value
        });
        if (responseCompany?.data?.results) {
          setDefaultCompany(responseCompany?.data?.results[0] || null);
        }
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao buscar a empresa padrao', severity: 'error' }));
    }
  };

  useEffect(() => {
    fetchDefaultCompany();
    fetchIndicatorFields();
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 500);
      debounced();
      return debounced.cancel;
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <>
            <Box mr={1}>
              <Autocomplete
                size="small"
                options={commercialIndicators}
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <TextField {...params} label="Indicadores" placeholder="Selecione um indicador" />}
                onChange={(_, value) => setSelectedIndicator(value)}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ width: 300 }}
              />
            </Box>
            <Tooltip title={selectedIndicator ? 'Filtros' : 'Selecione um indicador'}>
              <span>
                {' '}
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<FilterListIcon />}
                  sx={{ mr: 1 }}
                  onClick={handleShowFilter}
                  disabled={!selectedIndicator}
                >
                  Filtros
                </Button>
              </span>
            </Tooltip>
          </>
        )}
      </ButtonsContainer>
      <CommercialDashboardCard permissions={permissions} />
      {showFilter && permissions.hasReadPermission && (
        <CommercialDashboardFilterDialog
          onClose={handleCloseDialogs}
          indicatorId={selectedIndicator?.id!}
          defaultCompany={defaultCompany}
        />
      )}
    </>
  );
};
