import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { PermissionsObj } from '../../../hooks/useCrudPermissions';
import { IndicatorGrouped } from '../../../models/IndicatorGrouped';
import { IndicatorValues } from '../../../models/IndicatorValues';
import { SalesChannel } from '../../../models/SalesChannel';
import { useCommercialDashboardsPageContext } from '../context/CommercialDashboardContext';
import { CommercialDashboardDetailsTable } from './CommercialDashboardDetailsTable';
import { CommercialDashboardExceedTable } from './CommercialDashboardExceedTable';
import { CommercialDashboardTable } from './CommercialDashboardTable';
import { CommercialDashboardValuesTable } from './CommercialDashboardValuesTable';

interface Props {
  permissions: PermissionsObj;
}

export const CommercialDashboardCard = ({ permissions }: Props) => {
  const [selectedRow, setSelectedRow] = useState<IndicatorGrouped | null>(null);
  const [selectedRowDetail, setSelectedRowDetail] = useState<IndicatorValues | null>(null);
  const { filterFields } = useCommercialDashboardsPageContext();

  const handleRowClick = (row: IndicatorGrouped) => {
    setSelectedRow(row);
  };

  const handleRowDetailClick = (sales_channel: SalesChannel | number, representative: number | null = null, type: string) => {
    setSelectedRowDetail({ sales_channel, representative, type } as IndicatorValues);
  };

  const handleCloseDialogs = () => {
    setSelectedRowDetail(null);
  };

  useEffect(() => {
    setSelectedRow(null);
  }, [filterFields]);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <Box sx={{ padding: 2 }}>
            <CommercialDashboardTable permissions={permissions} onRowClick={handleRowClick} onCellClick={handleRowDetailClick} />
          </Box>
          <Box sx={{ padding: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Box sx={{ width: 500 }}>
              <CommercialDashboardExceedTable permissions={permissions} />
            </Box>
          </Box>
          {selectedRow && (
            <Box sx={{ padding: 2 }}>
              <Typography variant="h4" sx={{ pb: 2 }}>
                Detalhamento
              </Typography>
              <CommercialDashboardDetailsTable permissions={permissions} selectedRow={selectedRow} onRowClick={handleRowDetailClick} />
            </Box>
          )}
        </Paper>
      </Box>
      {selectedRowDetail && (
        <CommercialDashboardValuesTable onClose={handleCloseDialogs} selectedRow={selectedRowDetail} permissions={permissions} />
      )}
    </>
  );
};
