import { HelmetMeta } from '../../../layout/HelmetMeta';
import { InventoryGroupPageContextProvider } from '../../../sections/@inventory-group/context/InventoryGroupContext';
import { InventoryGroupPage } from '../../../sections/@inventory-group/InventoryGroupPage';

export const InventoryGroupPageWrapper = () => (
  <InventoryGroupPageContextProvider>
    <HelmetMeta pageName="Grupos de Estoque" />
    <InventoryGroupPage />
  </InventoryGroupPageContextProvider>
);
