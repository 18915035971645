import { HelmetMeta } from '../../../layout/HelmetMeta';
import { CommercialIndicatorPage } from '../../../sections/@commercial-indicators/CommercialIndicatorPage';
import { CommercialIndicatorPageContextProvider } from '../../../sections/@commercial-indicators/context/CommercialIndicatorContext';

export const CommercialIndicatorPageWrapper = () => (
  <CommercialIndicatorPageContextProvider>
    <HelmetMeta pageName="Indicadores Comerciais" />
    <CommercialIndicatorPage />
  </CommercialIndicatorPageContextProvider>
);
