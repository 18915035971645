import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { CustomerType } from '../models/CustomerType';

const CustomerTypeUrl = '/erp/customer-types/';

export interface CustomerTypeFilterFields extends CustomerType {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export const CustomerTypeService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(CustomerTypeUrl, {
        params: {
          page_size: 0
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },
  get: async (filterFields: CustomerTypeFilterFields, page: number = 0, page_size: number = 0) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(CustomerTypeUrl, {
        params: {
          ...filterFields
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
