import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';

const DefaultCompanyParameterUrl = '/default-company-parameter/';

export const DefaultCompanyParameterService = {
  get: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(DefaultCompanyParameterUrl);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
