import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { InventoryGoal } from '../../../models/InventoryGoal';
import { InventoryGoalService } from '../../../services/InventoryGoal.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface InventoryGoalFilterFields extends InventoryGoal {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface InventoryGoalResult extends ApiResults {
  results: InventoryGoal[];
}

interface InventoryGoalContextProps {
  invetoryGoals: InventoryGoalResult | undefined;
  fetchInventoryGoals: () => void;
  deleteInventoryGoals: (id: number) => Promise<void>;
  deleteManyInventoryGoals: (items: string[]) => Promise<void>;
  filterFields: InventoryGoalFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<InventoryGoalFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const InventoryGoalsPageContext = createContext<InventoryGoalContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const InventoryGoalPageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [invetoryGoals, setInventoryGoals] = useState<InventoryGoalResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<InventoryGoalFilterFields>({} as InventoryGoalFilterFields);

  const fetchInventoryGoals = async () => {
    dispatch(showSpinner());

    try {
      const response = await InventoryGoalService.get(filterFields, page, rowsPerPage);
      if (checkResponseStatus(response)) {
        setInventoryGoals(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteInventoryGoals = async (id: number) => {
    dispatch(showSpinner());
    try {
      await InventoryGoalService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registro excluído com sucesso!',
          severity: 'success'
        })
      );
      fetchInventoryGoals();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyInventoryGoals = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await InventoryGoalService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registros excluídos com sucesso!',
          severity: 'success'
        })
      );
      fetchInventoryGoals();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail ?? 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchInventoryGoals();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: InventoryGoalContextProps = {
    invetoryGoals,
    fetchInventoryGoals,
    deleteInventoryGoals,
    deleteManyInventoryGoals,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <InventoryGoalsPageContext.Provider value={contextValue}>{children}</InventoryGoalsPageContext.Provider>;
};

export const useInventoryGoalsPageContext = () => {
  const context = useContext(InventoryGoalsPageContext);
  if (!context) {
    throw new Error('useInventoryGoalsPageContext must be used within a InventoryGoalsPageContextProvider');
  }
  return context;
};
