import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Button, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useCrudPermissions } from '../../../hooks/useCrudPermissions';
import { InventoryGroup } from '../../../models/InventoryGroup';
import { InventoryGroupDetailsDialog } from '../InventoryGroupDetailsDialog';
import { InventoryGroupFilterDialog } from '../InventoryGroupFilterDialog';
import { InventoryGroupFormDialog } from '../InventoryGroupFormDialog';
import { InventoryGroupTable } from '../InventoryGroupTable';
import { useInventoryGroupsPageContext } from '../context/InventoryGroupContext';
import { ButtonsContainer } from './styles';

export const InventoryGroupPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'INVENTORY_GROUP'
  });

  const [showFilter, setShowFilter] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [editItem, setEditItem] = useState<InventoryGroup | undefined>(undefined);
  const [viewItem, setViewItem] = useState<InventoryGroup | undefined>(undefined);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields } = useInventoryGroupsPageContext();

  const handleAddUser = () => {
    setNewItem(true);
  };

  const handleViewItem = (item: InventoryGroup) => {
    setViewItem(item);
  };

  const handleEditItem = (item: InventoryGroup) => {
    setEditItem(item);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setNewItem(false);
    setEditItem(undefined);
    setViewItem(undefined);
  };

  useEffect(() => {
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 500);
      debounced();
      return debounced.cancel;
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <>
            <Tooltip title="Filtros">
              <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
                Filtros
              </Button>
            </Tooltip>
          </>
        )}
        {permissions.hasCreatePermission && (
          <Tooltip title="Criar Grupo de Estoque">
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddUser}>
              Adicionar Grupo de Estoque
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      <InventoryGroupTable onView={handleViewItem} onEdit={handleEditItem} permissions={permissions} />
      {showFilter && permissions.hasReadPermission && <InventoryGroupFilterDialog onClose={handleCloseDialogs} />}
      {viewItem && <InventoryGroupDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
      {(newItem || editItem) && permissions.hasCreatePermission && (
        <InventoryGroupFormDialog editItem={editItem} onClose={handleCloseDialogs} />
      )}
    </>
  );
};
