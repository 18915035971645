import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { ExceedTarget } from '../../../models/ExceedTarget';
import { FormMessage } from '../../../models/FormMessage';
import { ExceedTargetService } from '../../../services/ExceedTarget.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { ExceedTargetScheme } from '../../../utils/forms/validations/formValidations';
import { formatDateToApi, formatSimpleDate, setInputErrorsFromApi } from '../../../utils/utils';
import { useExceedTargetsPageContext } from '../context/ExceedTargetContext';

interface Props {
  editItem?: ExceedTarget;
  onClose: () => void;
}

const getFirstDayOfCurrentMonth = () => {
  const today = new Date();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const year = today.getFullYear();
  return `01/${month}/${year}`;
};

export const ExceedTargetFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const { user: userAuth } = useAuthContext();

  const { fetchExceedTargets } = useExceedTargetsPageContext();

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors }
  } = useForm<ExceedTarget>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ExceedTargetScheme),
    defaultValues: editItem
      ? {
          ...editItem,
          target_date: formatSimpleDate(editItem.target_date),
          percent: editItem.percent?.replace('.', ',')
        }
      : {
          target_date: getFirstDayOfCurrentMonth()
        }
  });
  const onSubmitForm: SubmitHandler<ExceedTarget> = async (data: ExceedTarget) => {
    data = {
      ...data,
      target_date: formatDateToApi(data.target_date),
      percent: data.percent.replace('%', '').replace('.', '').replace(',', '.'),
      user: userAuth?.id
    };
    dispatch(showSpinner());

    try {
      const response = editItem?.id ? await ExceedTargetService.update(data) : await ExceedTargetService.create(data);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Meta Superação atualizada com sucesso!' : 'Meta Superação criada com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchExceedTargets();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Nova'} Meta Superação
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel htmlFor="target_date">Data</InputLabel>
              <ControlledTextInput
                name="target_date"
                placeholder="Data"
                control={control}
                fullWidth
                mask="99/99/9999"
                errorMessage={errors.target_date?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="percent">Percentual</InputLabel>
              <ControlledTextInput
                name="percent"
                placeholder="Percentual"
                control={control}
                errorMessage={errors.percent?.message?.toString()}
                percentage={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
