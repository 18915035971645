import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { User } from '../../../models/User';
import { UsersService } from '../../../services/Users.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { findSelectedOption } from '../../../utils/utils';
import { InventoryGroupFilterFields, useInventoryGroupsPageContext } from '../context/InventoryGroupContext';

interface Props {
  onClose: () => void;
}

const filterIncludeOverallOptions: SelectOption[] = [
  { id: null, name: 'Todos' },
  { id: true, name: 'Sim' },
  { id: false, name: 'Não' }
];

const getDefaultValues = (filter?: InventoryGroupFilterFields): InventoryGroupFilterFields => {
  return {
    description: filter?.description ?? '',
    include_overall: findSelectedOption(filterIncludeOverallOptions, filter?.include_overall) ?? filterIncludeOverallOptions[0].id
  };
};

export const InventoryGroupFilterDialog = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const { filterFields, setFilterFields } = useInventoryGroupsPageContext();
  const [users, setUsers] = useState<SelectOption[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<InventoryGroupFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const fetchUsers = async () => {
    try {
      const response = await UsersService.get({ is_active: 1 });
      if (response?.data?.results) {
        const result = response?.data?.results;
        setUsers(
          result.map((users: User) => ({
            id: users.id,
            name: `${users.first_name} ${users.last_name}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    }
  };

  const onSubmit: SubmitHandler<InventoryGroupFilterFields> = async (data: InventoryGroupFilterFields) => {
    data = {
      ...data,
      supervisor: data?.supervisor?.id || null,
      include_overall: data?.include_overall?.id || null
    };
    setFilterFields({ ...data } as InventoryGroupFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as InventoryGroupFilterFields);
    onClose();
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      dispatch(showSpinner());

      await fetchUsers();

      setIsLoading(false);
      dispatch(hideSpinner());
    })();
  }, []);

  useEffect(() => {
    if (filterFields && filterFields.supervisor) {
      const selectedOption = users.find((option) => option.id === filterFields.supervisor);
      if (selectedOption) {
        setValue('supervisor', selectedOption);
      }
    }
  }, [users]);

  return (
    <Dialog open={!isLoading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="description">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Descrição"
                control={control}
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={6}>
              <InputLabel htmlFor="supervisor">Supervisor</InputLabel>
              <ControlledComboBox
                name="supervisor"
                control={control}
                placeholder="Supervisor"
                errorMessage={errors.supervisor?.message?.toString()}
                selectOptions={users}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel id="include_overall">Compõe Geral</InputLabel>
              <ControlledComboBox
                name="include_overall"
                placeholder="Compõe Geral"
                control={control}
                errorMessage={errors.include_overall?.message?.toString()}
                selectOptions={filterIncludeOverallOptions}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
