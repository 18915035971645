import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { UsersService } from '../../../services/Users.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { RepresentativeUserFilterFields, useRepresentativeUsersPageContext } from '../context/RepresentativeUserPageContext';
interface Props {
  onClose: () => void;
}

const getDefaultValues = (filter?: RepresentativeUserFilterFields): RepresentativeUserFilterFields => {
  return {
    focco_representative_code: filter?.focco_representative_code ?? '',
    focco_representative_description: filter?.focco_representative_description ?? '',
    focco_representative_trade_name: filter?.focco_representative_trade_name ?? '',
    focco_representative_cnpj: filter?.focco_representative_cnpj ?? '',
    focco_representative_cpf: filter?.focco_representative_cpf ?? ''
  };
};

export const RepresentativeUserFilterDialog = ({ onClose }: Props) => {
  const { filterFields, setFilterFields } = useRepresentativeUsersPageContext();
  const dispatch = useDispatch();
  const [users, setUsers] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<RepresentativeUserFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit: SubmitHandler<RepresentativeUserFilterFields> = async (data: RepresentativeUserFilterFields) => {
    data = {
      ...data,
      user: data?.user ? data.user.id : null
    };
    setFilterFields({ ...data } as RepresentativeUserFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as RepresentativeUserFilterFields);
    onClose();
  };

  const fetchUsers = async () => {
    try {
      const response = await UsersService.get({ is_active: true });
      if (response?.data?.results) {
        const dataUsers = response?.data?.results;
        setUsers(
          dataUsers.map((user: User) => ({
            id: user.id,
            name: `${user.first_name} ${user.last_name}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Usuários',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      dispatch(showSpinner());

      await fetchUsers();

      setIsLoading(false);
      dispatch(hideSpinner());
    })();
  }, []);

  return (
    <Dialog open={!isLoading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_representative_code">Código Representante</InputLabel>
              <ControlledTextInput
                name="focco_representative_code"
                placeholder="Código Representante"
                control={control}
                errorMessage={errors.focco_representative?.code?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_representative_description">Nome Representante</InputLabel>
              <ControlledTextInput
                name="focco_representative_description"
                placeholder="Nome Representante"
                control={control}
                errorMessage={errors.focco_representative?.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_representative_trade_name">Nome Fantasia</InputLabel>
              <ControlledTextInput
                name="focco_representative_trade_name"
                placeholder="Nome Fantasia"
                control={control}
                errorMessage={errors.focco_representative?.trade_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_representative_cnpj">CNPJ</InputLabel>
              <ControlledTextInput
                name="focco_representative_cnpj"
                placeholder="CNPJ"
                control={control}
                errorMessage={errors.focco_representative?.cnpj?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="focco_representative_cnpj">CPF</InputLabel>
              <ControlledTextInput
                name="focco_representative_cpf"
                placeholder="CPF"
                control={control}
                errorMessage={errors.focco_representative?.cpf?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="user">Usuários</InputLabel>
              <ControlledComboBox
                placeholder="Usuários"
                name="user"
                control={control}
                errorMessage={errors.user?.message?.toString()}
                selectOptions={users}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
