import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { CommercialIndicator } from '../../../models/CommercialIndicator';
import { CommercialIndicatorField } from '../../../models/CommercialIndicatorField';
import { FormMessage } from '../../../models/FormMessage';
import { CommercialIndicatorService } from '../../../services/CommercialIndicator.service';
import { CommercialIndicatorFieldService } from '../../../services/CommercialIndicatorField.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { CommercialIndicatorScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useCommercialIndicatorsPageContext } from '../context/CommercialIndicatorContext';

interface Props {
  editItem?: CommercialIndicator;
  onClose: () => void;
}

export const CommercialIndicatorFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const { user: userAuth } = useAuthContext();
  const [indicatorFields, setIndicatorFields] = useState<SelectOption[]>([]);

  const { fetchCommercialIndicators } = useCommercialIndicatorsPageContext();

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors }
  } = useForm<CommercialIndicator>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CommercialIndicatorScheme),
    defaultValues: editItem
      ? {
          ...editItem,
          fields: editItem.fields.map((field) => ({
            id: field.id,
            name: `${field.abbreviation} - ${field.description}`
          }))
        }
      : {
          status: true
        }
  });

  const watchStatus = useWatch({
    control,
    name: 'status',
    defaultValue: getValues('status')
  });

  const onSubmitForm: SubmitHandler<CommercialIndicator> = async (data: CommercialIndicator) => {
    data = {
      ...data,
      created_by: userAuth?.id,
      fields: data.fields.map((field) => field.id)
    };
    dispatch(showSpinner());

    try {
      const response = editItem?.id ? await CommercialIndicatorService.update(data) : await CommercialIndicatorService.create(data);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Indicador atualizado com sucesso!' : 'Indicador criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchCommercialIndicators();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchIndicatorFields = async () => {
    try {
      const response = await CommercialIndicatorFieldService.getAll();
      if (response?.data?.results) {
        const result = response?.data?.results;
        setIndicatorFields(
          result.map((indicatorField: CommercialIndicatorField) => ({
            id: indicatorField.id,
            name: `${indicatorField.abbreviation} - ${indicatorField.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    fetchIndicatorFields();
  }, []);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Indicador
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor="description">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Descrição"
                control={control}
                fullWidth
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Situação:</Typography>
              <ControlledCheckbox name="status" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>
            <Divider sx={{ marginY: 2 }} />
            <Grid item xs={12} md={12}>
              <InputLabel id="fields">Seleção de Campos</InputLabel>
              <ControlledComboBox
                name="fields"
                placeholder="Campos"
                control={control}
                errorMessage={errors.fields?.message?.toString()}
                selectOptions={indicatorFields}
                multiple={true}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
