import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { CustomerType } from '../../../models/CustomerType';
import { FormMessage } from '../../../models/FormMessage';
import { MarketSegment } from '../../../models/MarketSegment';
import { RepresentativeType } from '../../../models/RepresentativeType';
import { SaleDivision } from '../../../models/SaleDivision';
import { SalesChannel } from '../../../models/SalesChannel';
import { CustomerTypeService } from '../../../services/CustomerType.service';
import { MarketSegmentService } from '../../../services/MarketSegment.service';
import { RepresentativeTypeService } from '../../../services/RepresentativeType.service';
import { SaleDivisionService } from '../../../services/SaleDivision.service';
import { SalesChannelService } from '../../../services/SalesChannel.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { SalesChannelScheme } from '../../../utils/forms/validations/formValidations';
import { findSelectedOption, setInputErrorsFromApi } from '../../../utils/utils';
import { useSalesChannelsPageContext } from '../context/SalesChannelContext';

interface Props {
  editItem?: SalesChannel;
  onClose: () => void;
}

const getInputLabel = (criteriaType: string) => {
  switch (criteriaType) {
    case 'sale_division':
      return 'Divisão de Venda';
    case 'market_segment':
      return 'Segmento';
    case 'customer_type':
      return 'Tipo de Cliente';
    default:
      return 'Tipo de Representante';
  }
};

export const typeOptions: SelectOption[] = [
  { id: 'C', name: 'Comercial' },
  { id: 'F', name: 'Financeiro' }
];

const criteriasType: SelectOption[] = [
  { id: 'sale_division', name: 'Divisão de Venda' },
  { id: 'market_segment', name: 'Segmento' },
  { id: 'customer_type', name: 'Tipo de Cliente' },
  { id: 'representative_type', name: 'Tipo de Representante' }
];

export const SalesChannelFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);

  const [representativeTypes, setRepresentativeTypes] = useState<SelectOption[]>([]);

  const [saleDivisions, setSaleDivisions] = useState<SelectOption[]>([]);

  const [marketSegments, setMarketSegments] = useState<SelectOption[]>([]);

  const [customerTypes, setCustomersType] = useState<SelectOption[]>([]);

  const { fetchSalesChannels } = useSalesChannelsPageContext();

  const initializeCriterias = (criteriasData) => {
    return criteriasData.map((criteria) => ({
      ...criteria,
      values: criteria.criteria_values.map((value) => ({
        id: value.erp_id.id,
        name: value.erp_id.name
      }))
    }));
  };
  const [criterias, setCriterias] = useState(editItem?.criterias ? initializeCriterias(editItem.criterias) : []);

  useEffect(() => {
    if (editItem) {
      setCriterias(initializeCriterias(editItem.criterias));
    }
  }, [editItem]);

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors }
  } = useForm<SalesChannel>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(SalesChannelScheme),
    defaultValues: editItem ? { ...editItem, type: findSelectedOption(typeOptions, editItem?.type) ?? null } : { status: true }
  });

  const fetchSalesDivisions = async (watchSaleDivisionSearch?: string) => {
    try {
      const response = await SaleDivisionService.get({ search: watchSaleDivisionSearch });
      if (response?.data?.results) {
        const result = response?.data?.results;
        setSaleDivisions(
          result.map((salesDivision: SaleDivision) => ({
            id: salesDivision.id,
            name: `${salesDivision.code} - ${salesDivision.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    }
  };

  const fetchMarketSegments = async (watchMarketSegmentSearch?: string) => {
    try {
      const response = await MarketSegmentService.get({ search: watchMarketSegmentSearch });
      if (response?.data?.results) {
        const result = response?.data?.results;
        setMarketSegments(
          result.map((marketSegment: MarketSegment) => ({
            id: marketSegment.id,
            name: `${marketSegment.code} - ${marketSegment.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    }
  };

  const fetchCustomerTypes = async (watchCustomerTypeSearch?: string) => {
    try {
      const response = await CustomerTypeService.get({ search: watchCustomerTypeSearch });
      if (response?.data?.results) {
        const result = response?.data?.results;
        setCustomersType(
          result.map((customerType: CustomerType) => ({
            id: customerType.id,
            name: `${customerType.code} - ${customerType.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    }
  };

  const fetchRepresentativeTypes = async (watchRepresentativeTypeSearch?: string) => {
    try {
      const response = await RepresentativeTypeService.get({ search: watchRepresentativeTypeSearch });
      if (response?.data?.results) {
        const result = response?.data?.results;
        setRepresentativeTypes(
          result.map((representativeType: RepresentativeType) => ({
            id: representativeType.id,
            name: `${representativeType.code} - ${representativeType.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    }
  };

  const watchStatus = useWatch({
    control,
    name: 'status',
    defaultValue: getValues('status')
  });

  const onSubmitForm: SubmitHandler<SalesChannel> = async (data: SalesChannel) => {
    const formattedCriterias = criterias.reduce((acc, criteria) => {
      const existingCriteria = acc.find((c) => c.criteria_type === criteria.criteria_type);

      if (existingCriteria) {
        const uniqueValues = criteria.values.filter((value) => !existingCriteria.criteria_values.some((v) => v.erp_id === value.id));
        existingCriteria.criteria_values.push(...uniqueValues.map((value) => ({ sales_channel_criteria: null, erp_id: value.id })));
      } else {
        acc.push({
          sales_channel: null,
          criteria_type: criteria.criteria_type,
          criteria_values: criteria.values.map((value) => ({
            sales_channel_criteria: null,
            erp_id: value.id
          }))
        });
      }

      return acc;
    }, [] as any[]);

    const payload = {
      id: editItem?.id ?? null,
      description: data.description,
      detail_description: data.detail_description,
      status: data.status,
      type: data?.type?.id || null,
      criterias: formattedCriterias
    };

    dispatch(showSpinner());

    try {
      const response = editItem?.id ? await SalesChannelService.update(payload) : await SalesChannelService.create(payload);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Canal de Venda atualizado com sucesso!' : 'Canal de Venda criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchSalesChannels();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleAddCriterias = () => {
    setCriterias([...criterias, { criteria_type: '', values: [] }]);
  };

  const handleDeleteCriterias = (index: number) => {
    setCriterias(criterias.filter((_, i) => i !== index));
  };

  const handleCriteriaTypeChange = (index: number, type: string) => {
    const updatedCriterias = [...criterias];
    updatedCriterias[index].criteria_type = type;
    updatedCriterias[index].values = [];
    setCriterias(updatedCriterias);
  };

  const handleSelectValues = (index: number, values: SelectOption[]) => {
    const updatedCriterias = [...criterias];
    updatedCriterias[index].values = values;
    setCriterias(updatedCriterias);
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Canal de Venda
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor="description">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Descrição"
                control={control}
                fullWidth
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="type">Tipo</InputLabel>
              <ControlledComboBox
                name="type"
                placeholder="Tipo"
                control={control}
                errorMessage={errors.type?.message?.toString()}
                selectOptions={typeOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Situação:</Typography>
              <ControlledCheckbox name="status" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="detail_description">Descrição do Detalhamento</InputLabel>
              <ControlledTextInput
                name="detail_description"
                placeholder="Descrição do Detalhamento"
                control={control}
                fullWidth
                errorMessage={errors.detail_description?.message?.toString()}
              />
            </Grid>
          </Grid>

          {criterias.map((criteria, index) => (
            <div key={index}>
              <Divider sx={{ marginY: 2 }} />
              <Grid container spacing={2}>
                <Grid item xs={12} md={11}>
                  <InputLabel htmlFor="criterias">Critérios</InputLabel>
                  <Select
                    value={criteria.criteria_type}
                    onChange={(e) => handleCriteriaTypeChange(index, e.target.value)}
                    placeholder="Critérios"
                    size="small"
                    fullWidth
                  >
                    {criteriasType.map((criteriaType) => (
                      <MenuItem key={criteriaType.id} value={criteriaType.id}>
                        {criteriaType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={1}>
                  <IconButton sx={{ paddingTop: 2.3 }} onClick={() => handleDeleteCriterias(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={11}>
                  {criteria.criteria_type && (
                    <>
                      <InputLabel>{getInputLabel(criteria.criteria_type)}</InputLabel>
                      <AutocompleteSearch
                        value={criteria.values}
                        onSearch={
                          criteria.criteria_type === 'sale_division'
                            ? fetchSalesDivisions
                            : criteria.criteria_type === 'market_segment'
                            ? fetchMarketSegments
                            : criteria.criteria_type === 'customer_type'
                            ? fetchCustomerTypes
                            : fetchRepresentativeTypes
                        }
                        selectOptions={
                          criteria.criteria_type === 'sale_division'
                            ? saleDivisions
                            : criteria.criteria_type === 'market_segment'
                            ? marketSegments
                            : criteria.criteria_type === 'customer_type'
                            ? customerTypes
                            : representativeTypes
                        }
                        size="small"
                        onSelect={(event, values) => handleSelectValues(index, values)}
                        multiple
                        getOptionLabel={(option) => option.name}
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          ))}
          <Divider sx={{ marginY: 2 }} />
          <Button variant="outlined" onClick={handleAddCriterias} startIcon={<AddIcon />}>
            Adicionar Critério
          </Button>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
