export const ROUTES = {
  public: {
    login: '/login',
    signUp: '/cadastro',
    accountConfirmation: '/confirmacao-de-conta'
  },
  authenticated: {
    dashboard: {
      home: '/inicio'
    },
    user: {
      profile: '/perfil'
    },
    access: {
      users: '/usuarios',
      permissionGroups: '/grupos-permissoes'
    },
    auxiliary: {
      parameters: '/parameters'
    },
    finance: {
      payment_order: '/ordem-de-pagamento',
      availability: '/disponibilidade',
      availability_value: '/saldo-disponibilidade',
      position_availability_value: '/posicao-saldo'
    },
    integration: {
      link_representative: '/vincular-representante',
      link_sale_division: '/vincular-divisao-venda',
      link_customer: '/vincular-cliente',
      link_product: '/vincular-produto',
      link_sales_channel: '/vincular-canal-venda',
      integration_log: '/logs-integracao'
    },
    report: {
      financial: '/faturamento'
    },
    commercial: {
      sales_channel: '/canais-venda',
      exceed_target: '/meta-superacao',
      sales_channel_representative: '/canais-venda-representante',
      representative_user: '/representante-usuario',
      commercial_indicators: '/indicadores-comerciais',
      commercial_dashboard: '/painel-comercial'
    },
    invetory_analysis: {
      invertory_goals: '/metas-estoque',
      invertory_group: '/grupos-estoque'
    }
  }
};
