import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { SyntheticEvent, useEffect, useState } from 'react';
import { SelectOption } from '../ControlledComboBox';

interface Props {
  label?: string;
  value: SelectOption | SelectOption[] | null;
  defaultValue?: SelectOption | SelectOption[] | null;
  selectOptions?: SelectOption[];
  onSearch?: (searchValue: string) => Promise<any>;
  onSelect?: (event: SyntheticEvent<Element, Event>, value: any) => void;
  errorMessage?: string;
  disabled?: boolean;
  multiple?: boolean;
  getOptionLabel: (option: SelectOption) => string;
}

export const AutocompleteSearch = ({
  label,
  value,
  defaultValue,
  selectOptions = [],
  onSearch,
  onSelect,
  errorMessage,
  disabled,
  multiple = false,
  getOptionLabel,
  ...props
}: Props) => {
  const [inputValue, setInputValue] = useState<string | undefined>(
    multiple ? (defaultValue as SelectOption[] | null)?.map((item) => item.name).join(', ') : (defaultValue as SelectOption)?.name || ''
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<SelectOption[]>(selectOptions);

  const handleInputChange = async (event: any, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleSelectChange = (event: SyntheticEvent<Element, Event>, newValue: SelectOption[] | null) => {
    onSelect && onSelect(event, newValue || []);
  };

  useEffect(() => {
    const debounce = setTimeout(async () => {
      if (inputValue) {
        setLoading(true);
        onSearch && (await onSearch(inputValue));
        setLoading(false);
      }
    }, 500);
    return () => clearTimeout(debounce);
  }, [inputValue]);

  useEffect(() => {
    setSearchResults(selectOptions);
  }, [selectOptions]);

  return (
    <Autocomplete
      {...props}
      size="small"
      disabled={disabled}
      options={searchResults}
      getOptionLabel={(option: SelectOption) => option.name || ''}
      filterOptions={(options, state) => options}
      multiple={multiple}
      noOptionsText="Nenhum resultado encontrado"
      loading={loading}
      loadingText="Carregando..."
      onInputChange={handleInputChange}
      onChange={handleSelectChange}
      defaultValue={defaultValue}
      value={value || []}
      isOptionEqualToValue={(option, val) => option?.id === val?.id || option?.name === val?.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder="Digite para pesquisar"
          error={!!errorMessage}
          helperText={errorMessage}
        />
      )}
    />
  );
};
