import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { SalesChannel } from '../../../models/SalesChannel';
import { SalesChannelService } from '../../../services/SalesChannel.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import {
  SalesChannelRepresentativeFilterFields,
  useSalesChannelRepresentativesPageContext
} from '../context/SalesChannelRepresentativeContext';

interface Props {
  onClose: () => void;
}

export const SalesChannelRepresentativeFilterDialog = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const { filterFields, setFilterFields } = useSalesChannelRepresentativesPageContext();
  const [salesChannel, setSalesChannel] = useState<SelectOption[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<SalesChannelRepresentativeFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {}
  });

  const fetchSalesChannel = async () => {
    dispatch(showSpinner());
    try {
      const response = await SalesChannelService.getAll();
      if (response?.data?.results) {
        const result = response?.data?.results;
        setSalesChannel(
          result.map((salesChannel: SalesChannel) => ({
            id: salesChannel.id,
            name: salesChannel.description
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const onSubmit: SubmitHandler<SalesChannelRepresentativeFilterFields> = async (data: SalesChannelRepresentativeFilterFields) => {
    data = {
      ...data,
      sales_channel: data?.sales_channel?.id || null
    };
    setFilterFields({ ...data } as SalesChannelRepresentativeFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as SalesChannelRepresentativeFilterFields);
    onClose();
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      dispatch(showSpinner());

      await fetchSalesChannel();

      setIsLoading(false);
      dispatch(hideSpinner());
    })();
  }, []);

  useEffect(() => {
    if (filterFields && filterFields.sales_channel) {
      const selectedOption = salesChannel.find((option) => option.id === filterFields.sales_channel);
      if (selectedOption) {
        setValue('sales_channel', selectedOption);
      }
    }
  }, [salesChannel]);

  return (
    <Dialog open={!isLoading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <InputLabel htmlFor="sales_channel">Canal de Venda</InputLabel>
              <ControlledComboBox
                name="sales_channel"
                control={control}
                placeholder="Canal de Venda"
                errorMessage={errors.sales_channel?.message?.toString()}
                selectOptions={salesChannel}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
