import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Warehouse } from '../../../models/Warehouse';
import { WarehouseService } from '../../../services/Warehouse.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { formatDateToApi, formatSimpleDate } from '../../../utils/utils';
import { InventoryGoalFilterFields, useInventoryGoalsPageContext } from '../context/InventoryGoalContext';

interface Props {
  onClose: () => void;
}

const getDefaultValues = (filter?: InventoryGoalFilterFields): InventoryGoalFilterFields => {
  return {
    entry_date: filter?.entry_date ? formatSimpleDate(filter.entry_date) : ''
  };
};

export const InventoryGoalFilterDialog = ({ onClose }: Props) => {
  const dispatch = useDispatch();

  const { filterFields, setFilterFields } = useInventoryGoalsPageContext();
  const [warehouseERP, setWarehouseERP] = useState<SelectOption[]>([]);
  const [selectedWarehouseERP, setSelectedWarehouseERP] = useState<SelectOption>(filterFields?.warehouse || ({} as SelectOption));

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<InventoryGoalFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const onSubmit: SubmitHandler<InventoryGoalFilterFields> = async (data: InventoryGoalFilterFields) => {
    data = {
      ...data,
      entry_date: data.entry_date ? formatDateToApi(data.entry_date) : null,
      warehouse: selectedWarehouseERP?.id || null
    };
    setFilterFields({ ...data } as InventoryGoalFilterFields);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as InventoryGoalFilterFields);
    setSelectedWarehouseERP({});
    onClose();
  };

  const fetchWarehouseERP = async (watchWarehouseERPSearch?: string) => {
    try {
      const response = await WarehouseService.get({
        search: watchWarehouseERPSearch
      });
      if (response?.data?.results) {
        const dataAlmoxERP = response?.data?.results;
        setWarehouseERP(
          dataAlmoxERP.map((almox: Warehouse) => ({
            id: almox.id,
            name: `${almox.code} - ${almox.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Almoxarifados ERP',
          severity: 'error'
        })
      );
    }
  };

  const handleSelectWarehouseERP = (value: SelectOption) => {
    setSelectedWarehouseERP(value);
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <InputLabel htmlFor="entry_date">Período</InputLabel>
              <ControlledTextInput
                name="entry_date"
                placeholder="Período"
                control={control}
                fullWidth
                mask="99/99/9999"
                errorMessage={errors.entry_date?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <InputLabel htmlFor="almox_id">Almoxarifado</InputLabel>
              <AutocompleteSearch
                value={selectedWarehouseERP}
                onSearch={fetchWarehouseERP}
                selectOptions={warehouseERP}
                onSelect={(event, value: SelectOption) => handleSelectWarehouseERP(value)}
                errorMessage={errors.warehouse?.message}
                getOptionLabel={(option) => option.name!}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
