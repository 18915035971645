import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { RepresentativeUser } from '../../../models/RepresentativeUser';
import { RepresentativeUserService } from '../../../services/RepresentativeUser.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface RepresentativeUserFilterFields extends RepresentativeUser {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  focco_representative_code?: string;
  focco_representative_description?: string;
  focco_representative_trade_name?: string;
  focco_representative_cnpj?: string;
  focco_representative_cpf?: string;
}

export interface RepresentativeUserResult extends ApiResults {
  results: RepresentativeUser[];
}

interface RepresentativeUserContextProps {
  representativeUsers: RepresentativeUserResult | undefined;
  fetchRepresentativeUser: () => void;
  deleteRepresentativeUsers: (id: number) => Promise<void>;
  deleteManyRepresentativeUsers: (items: string[]) => Promise<void>;
  filterFields: RepresentativeUserFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<RepresentativeUserFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const RepresentativeUsersPageContext = createContext<RepresentativeUserContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const RepresentativeUserPageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [representativeUsers, setRepresentativeUsers] = useState<RepresentativeUserResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<RepresentativeUserFilterFields>({} as RepresentativeUserFilterFields);

  const fetchRepresentativeUser = async () => {
    dispatch(showSpinner());

    try {
      const response = await RepresentativeUserService.get(filterFields, page, rowsPerPage);
      if (checkResponseStatus(response)) {
        setRepresentativeUsers(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteRepresentativeUsers = async (id: number) => {
    dispatch(showSpinner());
    try {
      await RepresentativeUserService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registro excluído com sucesso!',
          severity: 'success'
        })
      );
      fetchRepresentativeUser();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyRepresentativeUsers = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await RepresentativeUserService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registros excluídos com sucesso!',
          severity: 'success'
        })
      );
      fetchRepresentativeUser();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail ?? 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchRepresentativeUser();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: RepresentativeUserContextProps = {
    representativeUsers,
    fetchRepresentativeUser,
    deleteRepresentativeUsers,
    deleteManyRepresentativeUsers,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <RepresentativeUsersPageContext.Provider value={contextValue}>{children}</RepresentativeUsersPageContext.Provider>;
};

export const useRepresentativeUsersPageContext = () => {
  const context = useContext(RepresentativeUsersPageContext);
  if (!context) {
    throw new Error('useRepresentativeUsersPageContext must be used within a RepresentativeUsersPageContextProvider');
  }
  return context;
};
