import { HelmetMeta } from '../../../layout/HelmetMeta';
import { ExceedTargetPageContextProvider } from '../../../sections/@exceed-target/context/ExceedTargetContext';
import { ExceedTargetPage } from '../../../sections/@exceed-target/ExceedTargetPage';

export const ExceedTargetPageWrapper = () => (
  <ExceedTargetPageContextProvider>
    <HelmetMeta pageName="Meta Superação" />
    <ExceedTargetPage />
  </ExceedTargetPageContextProvider>
);
