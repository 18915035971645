import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Autocomplete, Divider, FormHelperText, Grid, InputLabel, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { SelectOption } from '../../../components/basics/ControlledComboBox';
import { FormMessage } from '../../../models/FormMessage';
import { RDFunnel } from '../../../models/RDFunnel';
import { RDFunnelStage } from '../../../models/RDFunnelStage';
import { SalesChannel } from '../../../models/SalesChannel';
import { SalesChannelLink } from '../../../models/SalesChannelLink';
import { RDFunnelsService } from '../../../services/RDFunnel.service';
import { SalesChannelService } from '../../../services/SalesChannel.service';
import { SalesChannelLinkService } from '../../../services/SalesChannelLink.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { SalesChannelLinkSchema } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useSalesChannelLinksPageContext } from '../context/SalesChannelLinkPageContext';

interface Props {
  editItem?: SalesChannelLink;
  onClose: () => void;
}

export const SalesChannelLinkFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [rdFunnels, setRDFunnels] = useState<RDFunnel[]>([]);
  const [salesChannel, setSalesChannel] = useState<SelectOption[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSalesChannel, setSelectedSalesChannel] = useState<SelectOption>({} as SelectOption);
  const [selectedFunnelStage, setSelectedFunnelStage] = useState<RDFunnelStage | null>(null);

  const { fetchSalesChannelLinks } = useSalesChannelLinksPageContext();

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors }
  } = useForm<SalesChannelLink>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(SalesChannelLinkSchema),
    defaultValues: editItem
      ? { ...editItem }
      : {
          funnel_stage: null
        }
  });

  const onSubmitForm: SubmitHandler<SalesChannelLink> = async (data: SalesChannelLink) => {
    data = {
      ...data,
      funnel: selectedFunnelStage ? selectedFunnelStage.funnelId : null,
      funnel_stage: selectedFunnelStage ? selectedFunnelStage.stageId : null,
      funnel_stage_name: selectedFunnelStage ? selectedFunnelStage.stageName : null,
      sales_channel: selectedSalesChannel?.id
    };
    dispatch(showSpinner());
    try {
      const response = editItem?.id ? await SalesChannelLinkService.update(data) : await SalesChannelLinkService.create(data);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Vínculo atualizado com sucesso!' : 'Vínculo criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchSalesChannelLinks();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleSelectSaleDivision = (value: SelectOption) => {
    setSelectedSalesChannel(value);
  };

  const fetchRDFunnels = async () => {
    try {
      const response = await RDFunnelsService.getAll();
      if (response?.data?.results) {
        const dataRDFunnels = response.data.results;
        setRDFunnels(
          dataRDFunnels.flatMap((funnel: RDFunnel) =>
            funnel.deal_stages.map((stage: RDFunnelStage) => ({
              funnelName: funnel.name,
              funnelId: funnel.id,
              stageName: stage.name,
              stageId: stage.id
            }))
          )
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Funis e Etapas do RD Station',
          severity: 'error'
        })
      );
    }
  };

  const fetchSalesChannel = async (watchSalesChannelSearch?: string) => {
    try {
      const response = await SalesChannelService.get({ search: watchSalesChannelSearch, status: true });
      if (response?.data?.results) {
        const dataSalesChannel = response?.data?.results;
        setSalesChannel(
          dataSalesChannel.map((sales_channel: SalesChannel) => ({
            id: sales_channel.id,
            name: sales_channel.description
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Canais de Venda',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      dispatch(showSpinner());

      await fetchRDFunnels();

      setIsLoading(false);
      dispatch(hideSpinner());
    })();
  }, []);

  useEffect(() => {
    if (editItem && rdFunnels.length > 0) {
      const funnelStage = rdFunnels.find((stage) => stage.stageId === editItem.funnel_stage);
      if (funnelStage) {
        setValue('funnel_stage', funnelStage);
        setSelectedFunnelStage(funnelStage);
      }
    }
  }, [rdFunnels]);

  useEffect(() => {
    if (editItem?.sales_channel) {
      const sales_channel = editItem.sales_channel as SalesChannel;
      setSelectedSalesChannel({ id: sales_channel.id, name: sales_channel.description });
      setSalesChannel([{ id: sales_channel.id, name: sales_channel.description }]);
    }
  }, [editItem]);

  return (
    <Dialog open={!isLoading} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Vínculo de Canal de Venda
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {formMessage?.message && (
                <Grid item xs={12}>
                  <Alert severity={formMessage?.severity} variant="outlined" sx={{ width: '100%' }}>
                    {formMessage.message}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="sales_channel">Canal de Venda</InputLabel>
              <AutocompleteSearch
                value={selectedSalesChannel}
                defaultValue={
                  editItem?.sales_channel
                    ? {
                        id: editItem.sales_channel.id,
                        name: editItem.sales_channel.description
                      }
                    : undefined
                }
                onSearch={fetchSalesChannel}
                selectOptions={salesChannel}
                onSelect={(event, value: SelectOption) => handleSelectSaleDivision(value)}
                errorMessage={errors.sales_channel?.message}
                getOptionLabel={(option) => option.name}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="funnel_stage">Etapa do Funil</InputLabel>
              <Controller
                name="funnel_stage"
                control={control}
                render={({ field: { onChange, value, ref } }) => (
                  <>
                    <Autocomplete
                      name="funnel_stage"
                      id="grouped-demo"
                      size="small"
                      options={rdFunnels.sort((a, b) => a.funnelName.localeCompare(b.funnelName))}
                      groupBy={(option) => option.funnelName}
                      getOptionLabel={(option) => option.stageName}
                      value={value}
                      onChange={(event, newValue) => {
                        onChange(newValue);
                        setSelectedFunnelStage(newValue);
                      }}
                      sx={{ width: '100%' }}
                      renderInput={(params) => <TextField {...params} inputRef={ref} />}
                    />
                    {errors.funnel_stage && <FormHelperText sx={{ color: 'red' }}>{errors.funnel_stage.message}</FormHelperText>}
                  </>
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
