import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { StyledTableRow } from '../../../../components/basics/StyledTableRow';
import { PermissionsObj } from '../../../../hooks/useCrudPermissions';
import { formatCurrency } from '../../../../utils/utils';
import { useCommercialDashboardsPageContext } from '../../context/CommercialDashboardContext';

interface Props {
  permissions: PermissionsObj;
}

export const CommercialDashboardExceedTable = ({ permissions }: Props) => {
  const { indicatorTarget } = useCommercialDashboardsPageContext();
  const targetData = indicatorTarget?.results[0];

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={'small'}>
            <TableBody>
              {indicatorTarget && (
                <>
                  <StyledTableRow role="checkbox" tabIndex={-1} key={targetData?.id} sx={{ cursor: 'pointer' }}>
                    <TableCell
                      align="left"
                      variant="head"
                      sx={{ backgroundColor: '#c1c1c1', fontWeight: 'bold', textDecoration: 'underline' }}
                    >
                      META SUPERAÇÃO
                    </TableCell>
                    <TableCell align="right">{formatCurrency(targetData?.value_target_exceed) || '-'}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow role="checkbox" tabIndex={-1} key={'percent'} sx={{ cursor: 'pointer' }}>
                    <TableCell
                      align="left"
                      variant="head"
                      sx={{ backgroundColor: '#c1c1c1', fontWeight: 'bold', textDecoration: 'underline' }}
                    >
                      PERCENTUAL (até a data atual)
                    </TableCell>
                    <TableCell align="right">{targetData?.percent_target_exceed || '-'} %</TableCell>
                  </StyledTableRow>
                  <StyledTableRow role="checkbox" tabIndex={-1} key={'target'} sx={{ cursor: 'pointer' }}>
                    <TableCell
                      align="left"
                      variant="head"
                      sx={{ backgroundColor: '#c1c1c1', fontWeight: 'bold', textDecoration: 'underline' }}
                    >
                      META DO MÊS
                    </TableCell>
                    <TableCell align="right">{formatCurrency(targetData?.value_target) || '-'}</TableCell>
                  </StyledTableRow>
                  <StyledTableRow role="checkbox" tabIndex={-1} key={'percent_total'} sx={{ cursor: 'pointer' }}>
                    <TableCell
                      align="left"
                      variant="head"
                      sx={{ backgroundColor: '#c1c1c1', fontWeight: 'bold', textDecoration: 'underline' }}
                    >
                      PERCENTUAL (até a data atual)
                    </TableCell>
                    <TableCell align="right">{targetData?.percent_target || '-'} %</TableCell>
                  </StyledTableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
