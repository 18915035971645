import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider, Grid, IconButton, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../components/basics/AutocompleteSearch';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { FormMessage } from '../../../models/FormMessage';
import { Representative } from '../../../models/Representative';
import { SalesChannel } from '../../../models/SalesChannel';
import { SalesChannelRepresentative } from '../../../models/SalesChannelRepresentative';
import { RepresentativesService } from '../../../services/Representative.service';
import { SalesChannelService } from '../../../services/SalesChannel.service';
import { SalesChannelRepresentativeService } from '../../../services/SalesChannelRepresentative.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { SalesChannelRepresentativeScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useSalesChannelRepresentativesPageContext } from '../context/SalesChannelRepresentativeContext';

interface Props {
  editItem?: SalesChannelRepresentative;
  onClose: () => void;
}

export const SalesChannelRepresentativeFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [linkedRepresentatives, setLinkedRepresentatives] = useState(
    (editItem?.representatives || []).map((rep) => ({
      ...rep,
      name: `${rep.code} - ${rep.description}`
    }))
  );
  const [representatives, setRepresentatives] = useState<SelectOption[]>([]);
  const [salesChannel, setSalesChannel] = useState<SelectOption[]>([]);

  const [selectedRepresentatives, setSelectedRepresentatives] = useState<{ [key: number]: SelectOption }>({});
  const { fetchSalesChannelRepresentatives } = useSalesChannelRepresentativesPageContext();

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    unregister,
    reset,
    formState: { errors }
  } = useForm<SalesChannelRepresentative>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(SalesChannelRepresentativeScheme),
    defaultValues: editItem
      ? {
          ...editItem,
          sales_channel: editItem?.sales_channel
            ? ({ id: editItem.sales_channel?.id, name: editItem.sales_channel.description } as SelectOption)
            : null
        }
      : {}
  });

  const onSubmitForm: SubmitHandler<SalesChannelRepresentative> = async (data: SalesChannelRepresentative) => {
    const payload = {
      id: editItem?.id || null,
      sales_channel: data.sales_channel?.id,
      representatives: linkedRepresentatives.map((representative) => representative.id)
    };
    dispatch(showSpinner());

    try {
      const response = editItem?.id
        ? await SalesChannelRepresentativeService.update(payload)
        : await SalesChannelRepresentativeService.create(payload);
      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Cadastro atualizado com sucesso!' : 'Cadastro criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchSalesChannelRepresentatives();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data) || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchRepresentative = async (watchRepresentativeSearch?: string) => {
    try {
      const response = await RepresentativesService.get({ search: watchRepresentativeSearch });
      if (response?.data?.results) {
        const dataRepresentatives = response?.data?.results;
        setRepresentatives(
          dataRepresentatives.map((representative: Representative) => ({
            id: representative.id,
            name: `${representative.code} - ${representative.description}`,
            cnpj: representative.cnpj,
            cpf: representative.cpf
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Representantes Focco',
          severity: 'error'
        })
      );
    }
  };

  const fetchSalesChannel = async () => {
    dispatch(showSpinner());
    try {
      const response = await SalesChannelService.getAll();
      if (response?.data?.results) {
        const result = response?.data?.results;
        setSalesChannel(
          result.map((salesChannel: SalesChannel) => ({
            id: salesChannel.id,
            name: salesChannel.description
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleSelectRepresentative = (value: SelectOption, index: number) => {
    setSelectedRepresentatives({
      ...selectedRepresentatives,
      [index]: value
    });

    const updatedRepresentatives = [...linkedRepresentatives];
    updatedRepresentatives[index] = {
      ...updatedRepresentatives[index],
      id: value.id,
      description: value.name,
      code: value.code,
      trade_name: value.trade_name,
      cnpj: value.cnpj,
      cpf: value.cpf
    };
    setLinkedRepresentatives(updatedRepresentatives);
  };

  const handleAddLinkedRepresentative = () => {
    setLinkedRepresentatives([...linkedRepresentatives, { id: null, description: '', code: '', trade_name: '', cnpj: '', cpf: '' }]);
    setSelectedRepresentatives({
      ...selectedRepresentatives,
      [linkedRepresentatives.length]: {}
    });
  };

  const handleDeleteLinkedRepresentative = (index: number) => {
    const updatedRepresentatives = linkedRepresentatives.filter((_, i) => i !== index);

    const updatedSelectedRepresentatives: { [key: number]: SelectOption } = {};
    updatedRepresentatives.forEach((rep, i) => {
      updatedSelectedRepresentatives[i] = selectedRepresentatives[i >= index ? i + 1 : i];
    });

    setLinkedRepresentatives(updatedRepresentatives);
    setSelectedRepresentatives(updatedSelectedRepresentatives);
  };

  useEffect(() => {
    fetchSalesChannel();
  }, []);

  useEffect(() => {
    const initialSelected = linkedRepresentatives.reduce((acc, rep, index) => {
      acc[index] = { id: rep.id, name: `${rep.code} - ${rep.description}` };
      return acc;
    }, {} as { [key: number]: SelectOption });
    setSelectedRepresentatives(initialSelected);
  }, []);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Atualizar' : 'Cadastrar'} Canais de Venda x Representantes
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor="sales_channel">Canal de Venda</InputLabel>
              <ControlledComboBox
                name="sales_channel"
                control={control}
                placeholder="Canal de Venda"
                errorMessage={errors.sales_channel?.message?.toString()}
                selectOptions={salesChannel}
              />
            </Grid>
          </Grid>
          <Divider sx={{ marginY: 2 }} />
          <Typography variant="body1" color="secondary" sx={{ marginBottom: 2 }} gutterBottom>
            Representantes:
          </Typography>
          {linkedRepresentatives.map((representative, index) => (
            <div key={index}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={11}>
                  <AutocompleteSearch
                    value={selectedRepresentatives[index] || {}}
                    defaultValue={
                      representative
                        ? {
                            id: representative.id,
                            name: `${representative.code} - ${representative.description}`
                          }
                        : undefined
                    }
                    onSearch={fetchRepresentative}
                    selectOptions={representatives}
                    onSelect={(event, value: SelectOption) => handleSelectRepresentative(value, index)}
                    getOptionLabel={(option) =>
                      `${option.name} ${option.cnpj ? ` - CNPJ: ${option.cnpj}` : ''} ${option.cpf ? ` - CPF: ${option.cpf}` : ''}`
                    }
                  />
                </Grid>
                <Grid item xs={12} md={1}>
                  <IconButton onClick={() => handleDeleteLinkedRepresentative(index)}>
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Divider sx={{ marginY: 2 }} />
            </div>
          ))}
          <Grid item sm={12}>
            <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleAddLinkedRepresentative}>
              Adicionar Representante
            </Button>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmitForm)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
